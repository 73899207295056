import { SpinnerCircular } from "spinners-react"

interface ButtonProps{
  isLoading?: boolean,
  disabled?: boolean,
  onClick: () => void,
  text: string;
  className: string
}

const Button: React.FC<ButtonProps> = ( props ) => {
  
  const createDisabledStyle = () => {
    if (props.className.includes("rounded")) {
      return "bg-[#7ed856] flex justify-around bg-opacity-60 transition-all w-full p-[14px] text-[white] pl-6 pr-6 text-sm font-medium " + props.className
    }
    return "bg-[#7ed856] flex justify-around bg-opacity-60 transition-all w-full p-[14px] text-[white] pl-6 pr-6 text-sm font-medium rounded-lg " + props.className
  }

  const createStyle = () => {
    if (props.className.includes("rounded")) {
      return "bg-[#7ed856] w-full transition-all p-[14px] hover:bg-opacity-60 text-[white] pl-6 pr-6 text-sm font-medium " + props.className
    }
    return "bg-[#7ed856] w-full transition-all p-[14px] text-[white] hover:bg-opacity-60 pl-6 pr-6 text-sm font-medium rounded-lg " + props.className
  }

  if (props.isLoading) {
    return(
      <button 
        disabled 
        className={createDisabledStyle()}
      >
        <SpinnerCircular color="white" secondaryColor="transparent" thickness={224} size={21} />
      </button>
    )
  }
  if (props.disabled) {
    return(
      <button 
        disabled 
        className={createDisabledStyle()}
      >
        {props.text}
      </button>
    )
  }
  return(
      <button 
        onClick={props.onClick} 
        className={createStyle()}
      >
        {props.text}
      </button>
  )
}

export default Button