import React from "react";
import { MdDone } from "react-icons/md"

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (e: any) => void;
}


const CustomCheckbox: React.FC<CustomCheckboxProps> = ( props ) => {
  return(
    <label className="cursor-pointer">
      <input className="hidden" onChange={(e) => props.onChange(e)} checked={props.checked} type="checkbox"/>
      {props.checked ? (
        <div className="w-[18px] flex justify-around items-center h-[18px] rounded-md text-white bg-[#7ed856]" >
          <MdDone size={10}/>
        </div>
      ):(
        <div className="w-[18px] h-[18px] justify-around items-center text-white rounded-md border" >

        </div>
      )}
    </label>
  )
}

export default CustomCheckbox