
interface DashboardCardProps {
  scans: number;
  old_scans: number;
  label: string;
  title: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ( props ) => {
  return (
    <div className="bg-[white] shadow-sm mt-[14px] h-[118px] p-[18px] mt-4 w-64 border rounded-lg">
      <h1 className="text-sm">{props.title}</h1>
      <p className="font-semibold mt-2 text-[#2C2C2C] text-2xl">{props.scans}</p>
      {props.scans > props.old_scans ? (
        <p className="text-[#7ed856] text-xs font-medium">+{props.scans - props.old_scans} from {props.label}</p>
      ):(
        props.scans < props.old_scans ? (
          <p className="text-[red] text-xs">-{props.old_scans - props.scans} from {props.label}</p>
        ):(
          <p className="text-[gray] text-xs">+0 from {props.label}</p>
        )
      )}
    </div>
  );
};

export default DashboardCard;