import React from "react";

interface WorkingModalProps {
  visible: boolean;
}

const WorkingModal: React.FC<WorkingModalProps> = ( props ) => {

  if (!props.visible) {
    return null
  }

  return (
    <div>
      <div className="w-screen z-10 h-screen fixed flex justify-around items-center bg-[black] bg-opacity-30">
        <div className="bg-[white] w-80 top-0 p-6 rounded-xl">
          <img width={40.4} src={require("../../../images/logo2.png")} alt="" />
          <h1 className="font-semibold max-w-64 mt-2 text-xl">Just a bit more patience...</h1>
          <p className="max-w-64 mt-4 text-sm">We're working to make Bliddo better, follow us on <span className="text-[#7ed856] underline font-semibold"><a href="https://x.com/@Bliddo_com" target="_blank">X</a></span> for updates.</p>
        </div>
      </div>
    </div>
  );
}

export default WorkingModal
