import axios from "axios";
import React, { useEffect } from "react";
import { MdClose, MdRestartAlt } from "react-icons/md";
import { BASE_URL, getCookie, isUrlInformationValid } from "../../../utils";
import { FaEnvelope, FaLink, FaPhone, FaSms } from "react-icons/fa";
import CustomQRCode from "../../qrcode";
import { urlInformationSchema } from "../../../constants";
import { QRCodeSchema, QRCodeTypeSchema, UrlInformationSchema } from "../../../types";
import ColorPicker from "../../color_picker";
import Input from "../../input";
import Button from "../../button";
import { Option, Select } from "@mui/joy";
import ModalClose from "../../modal_close";
import PhonePrefixSelector from "../../phone_prefix_selector";

interface CreateModalProps {
  visible: boolean;
  customizable: boolean;
  qrCode: QRCodeSchema;
  customization: boolean;
  onClose: (trigger?: boolean) => void;
}

const CreateModal: React.FC<CreateModalProps> = ( props ) => {

  const [data, setData] = React.useState<any>({
    logo_url: '',
    name: '',
    custom_logo: false,
    qrcode_id: 0,
    url: '',
    bg_color: '#ffffff',
    fg_color: '#000000',
    border_color: '#7ed856'
  })
  const [urlInformation, setUrlInformation] = React.useState<UrlInformationSchema>(urlInformationSchema)
  const [readyToCreate, setReadyToCreate] = React.useState(false)
  const [edited, setEdited] = React.useState(false)
  const [qrCodeType, setQrCodeType] = React.useState<QRCodeTypeSchema>("url")
  const [error, setError] = React.useState('')
  const [customization, setCustomization] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  useEffect(() => {
    setCustomization(props.customization)
    if (props.customization) {
      setData({
        logo_url: props.qrCode.logo_url,
        custom_logo: props.qrCode.custom_logo,
        name: props.qrCode.name,
        qrcode_type: props.qrCode.qrcode_type,
        qrcode_id: props.qrCode.qrcode_id,
        url: props.qrCode.url,
        bg_color: props.qrCode.bg_color,
        fg_color: props.qrCode.fg_color,
        border_color: props.qrCode.border_color
      })
    }
  }, [props.customization])


  const handleData = (e: any) => {
    if (e.target.name.includes("color")) {
      if (e.target.value.length < 1 || e.target.value.length > 7) {
        return
      }
    }
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
    setEdited(true)
  }

  const createQRCode = async () => {
    setIsLoading(true)
    await axios.post(BASE_URL + '/qrcodes/', { ...data, qrcode_type: qrCodeType, url: buildUrl()}, {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    })
    .then(res => {
      if (props.customizable) {
        setEdited(true)
        setCustomization(true)
        setData({
          ...data,
          qrcode_id: res.data.param.qrcode_id,
        })
        return
      }
      handleClose(true)
    })
    .catch(err => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const buildUrl = () => {
    if (qrCodeType === "url") {
      return urlInformation.url.url
    }
    if (qrCodeType === "email") {
      return "mailto:" + urlInformation.email.email + "?subject=" + urlInformation.email.subject + "&body=" + urlInformation.email.body
    }
    if (qrCodeType === "call") {
      return "tel:" + urlInformation.call.prefix + urlInformation.call.phone
    }
    if (qrCodeType === "sms") {
      return "sms:" + urlInformation.sms.prefix + urlInformation.sms.phone + "?body=" + urlInformation.sms.message
    }
    if (qrCodeType === "wifi") {
      return "WIFI:S:" + urlInformation.wifi.ssid + ";T:WPA;P:" + urlInformation.wifi.password + ";;"
    }
    if (qrCodeType === "geo") {
      return urlInformation.geo.maps_url
    }
    if (qrCodeType === "contact") {
      return "BEGIN:VCARD\nVERSION:3.0\nFN:" + urlInformation.contact.name + "\nEMAIL:" + urlInformation.contact.email + "\nTEL:" + urlInformation.contact.phone + "\nADR:" + urlInformation.contact.address + "\nEND:VCARD"
    }
  }

  const customizeQRCode = async () => {
    setIsLoading(true)
    await axios.put(BASE_URL + '/qrcodes/' + data.qrcode_id, data, {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    })
    .then(res => {
      handleClose(true)
    })
    .catch(err => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const handleLogo = (e: any) => {
    const file = e.target.files[0]
    const ext = file.name.split(".")[1]
    if (ext == "jpg") {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setData({
          ...data,
          custom_logo: true,
          logo_url: (reader.result as string).split(",")[1]
        })
      }
      setEdited(true)
    }
  }

  const canCreateQrCode = () => {
    if (data.url.split("//")[1]?.split(".").length < 2){
      return false
    }
    if (data.url.split("//")[1]?.split(".")[1].length < 1){
      return false
    }
    if (createQRCode.name.length > 18 || data.name.length == 0){
      return false
    }
    return true
  }


  const resetLogo = () => {
    setData({
      ...data,
      custom_logo: false,
      logo_url: ''
    })
    setEdited(true)
  }

  const getLogoUrl = () => {
    if (data.logo_url.startsWith("https://") || data.logo_url.startsWith("http://")) {
      return data.logo_url
    }
    return "data:image/jpg;base64," + data.logo_url
  }

  const handleUrlInformation = (e: any) => {
    setUrlInformation({
      ...urlInformation,
      [qrCodeType]: {
        ...urlInformation[qrCodeType],
        [e.target.name]: e.target.value
      }
    })
  }

  const handleUrlPrefix = (value: string) => {
    setUrlInformation({
      ...urlInformation,
      [qrCodeType]: {
        ...urlInformation[qrCodeType],
        prefix: value
      }
    })
  }

  const colorsValid = () => {
    return (
      data.bg_color.length == 7 && 
      data.fg_color.length == 7 &&
      data.border_color.length == 7 &&
      data.bg_color.includes("#") &&
      data.fg_color.includes("#") &&
      data.border_color.includes("#")
    )
  }

  const handleClose = (refreshRequired: boolean) => {
    setData({
      logo_url: '',
      name: '',
      custom_logo: false,
      qrcode_id: 0,
      url: '',
      qrcode_type: 'url',
      bg_color: '#ffffff',
      fg_color: '#000000',
      border_color: '#7ed856'
    })
    setCustomization(false)
    setIsLoading(false)
    setError('')
    setQrCodeType("url")
    setEdited(false)
    setReadyToCreate(false)
    setUrlInformation(urlInformationSchema)
    props.onClose(refreshRequired)
  }

  if (!props.visible) {
    return null
  }

  if (customization) {
    return (
      <div>
        <div className="w-screen z-10 top-0 left-0 h-screen absolute flex justify-around items-center bg-[black] bg-opacity-30">
          <div className="bg-[white] p-6 rounded-xl w-80">
            <div className="flex justify-between">
              <div></div>
              <button className="rounded-full p-1 bg-[#fafafa] transition-all" onClick={() => handleClose(false)}>
                <MdClose color="gray" size={18}/>
              </button>
            </div>
            <img width={40.4} src={require("../../../images/logo2.png")} alt="" />
            <h1 className="font-semibold max-w-64 mt-2 text-xl">Customize your QR Code</h1>
            <div className="flex mt-[14px] justify-around">
              <CustomQRCode
                watermark={false}
                customLogo={data.custom_logo}
                customizable={true}
                logo_url={getLogoUrl()}
                name={data.name}
                qrcode_type={data.qrcode_type}
                flow_url={data.url}
                bgColor={data.bg_color}
                fgColor={data.fg_color}
                size={184}
                borderColor={data.border_color}
              />
            </div>
            <div className="mt-6">
              <p>Custom logo <span className="text-[gray] italic">(Optional)</span></p>
              <div className="flex items-center gap-2">
                <input accept=".jpg" onChange={(e) => handleLogo(e)} className="border border-[#CDD7E1] bg-[#FBFCFE] mt-1 shadow-sm rounded-md p-2 h-full w-full" type="file"/>
                <div>
                  <button onClick={() => resetLogo()} className="p-2 rounded-full text-[white] bg-[#7ed856]"><MdRestartAlt/></button>
                </div>
              </div>
            </div>
            <ColorPicker
              name="bg_color"
              className="mt-6"
              label="Background"
              color={data.bg_color}
              onChange={(e) => handleData(e)}
            />
            <ColorPicker
              name="fg_color"
              className="mt-2"
              label="Color"
              color={data.fg_color}
              onChange={(e) => handleData(e)}
            />
            <ColorPicker
              name="border_color"
              className="mt-2"
              label="Border"
              color={data.border_color}
              onChange={(e) => handleData(e)}
            />
            <p className="text-sm mt-2 text-[red]" >{error}</p>
            <Button
              onClick={customizeQRCode}
              text="NEXT"
              disabled={!edited || !colorsValid()}
              className="mt-[34px]"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className="w-screen z-10 top-0 left-0 h-screen absolute flex justify-around items-center bg-[black] bg-opacity-30">
        <div className="bg-[white] p-6 rounded-xl w-80">
          <div className="flex justify-between">
            <div></div>
            <ModalClose onClick={() => handleClose(false)}/>
          </div>
          <img width={40.4} src={require("../../../images/logo2.png")} alt="" />
          <h1 className="font-semibold max-w-64 mt-2 text-xl">Create a QR Code</h1>
          {readyToCreate ? (
            <div>
              {qrCodeType === "url" ? (
                <Input
                  type="text"
                  placeHolder="Enter the URL"
                  label="Url"
                  className="mt-2"
                  name="url"
                  onChange={(e) => handleUrlInformation(e)}
                  value={urlInformation.url.url}
                />
              ):(
                qrCodeType === "email" ? (
                  <div>
                    <Input
                      type="text"
                      placeHolder="Enter the e-mail"
                      label="E-mail"
                      className="mt-6"
                      name="email"
                      onChange={(e) => handleUrlInformation(e)}
                      value={urlInformation.email.email}
                    />
                    <Input 
                      type="text"
                      label="Subject"
                      placeHolder="Enter the subject"
                      className="mt-2"
                      name="subject"
                      onChange={(e) => handleUrlInformation(e)}
                      value={urlInformation.email.subject}
                    />
                    <Input 
                      type="text"
                      className="mt-2"
                      placeHolder="Enter the body"
                      label="Body"
                      name="body"
                      onChange={(e) => handleUrlInformation(e)}
                      value={urlInformation.email.body}
                    />
                  </div>
                ):(
                  qrCodeType === "call" ? (
                    <div className="mt-4">
                      <p>Phone</p>
                      <div className="flex gap-2">
                        <PhonePrefixSelector
                          onChange={(value) => handleUrlPrefix(value)}
                        />
                        <Input
                          type="text"
                          placeHolder="123 456 7890"
                          name="phone"
                          onChange={(e) => handleUrlInformation(e)}
                          value={urlInformation.call.phone}
                        />
                      </div>
                    </div>
                  ):(
                    qrCodeType === "sms" ? (
                      <div>
                        <div className="mt-4">
                          <p>Phone</p>
                          <div className="flex gap-2">
                            <PhonePrefixSelector
                              onChange={(value) => handleUrlPrefix(value)}
                            />
                            <Input
                              type="text"
                              placeHolder="123 456 7890"
                              name="phone"
                              onChange={(e) => handleUrlInformation(e)}
                              value={urlInformation.sms.phone}
                            />
                          </div>
                        </div>
                        <Input 
                          type="text"
                          label="Message"
                          placeHolder="Enter the message"
                          className="mt-2"
                          name="message"
                          onChange={(e) => handleUrlInformation(e)}
                          value={urlInformation.sms.message}
                          />
                      </div>
                    ):(
                      qrCodeType === "wifi" ? (
                        <div>
                          <Input 
                            type="text"
                            label="SSID"
                            className="mt-6"
                            name="ssid"
                            onChange={(e) => handleUrlInformation(e)}
                            value={urlInformation.wifi.ssid}
                          />
                          <Input 
                            type="text"
                            label="Password"
                            className="mt-2"
                            name="password"
                            onChange={(e) => handleUrlInformation(e)}
                            value={urlInformation.wifi.password}
                          />
                        </div>
                      ):(
                        qrCodeType === "geo" ? (
                          <Input 
                            type="text"
                            label="Maps URL"
                            className="mt-6"
                            onChange={(e) => handleUrlInformation(e)}
                            name="maps_url"
                            value={urlInformation.geo.maps_url}
                          />
                        ):(
                          qrCodeType === "contact" ? (
                            <div>
                              <Input 
                                type="text"
                                label="Name"
                                className="mt-6"
                                name="name"
                                onChange={(e) => handleUrlInformation(e)}
                                value={urlInformation.contact.name}
                              />
                              <Input 
                                type="text"
                                placeHolder="Enter the e-mail"
                                label="E-mail"
                                className="mt-2"
                                name="email"
                                onChange={(e) => handleUrlInformation(e)}
                                value={urlInformation.contact.email}
                              />
                              <Input 
                                type="text"
                                placeHolder="Enter the phone"
                                label="Phone"
                                className="mt-2"
                                name="phone"
                                onChange={(e) => handleUrlInformation(e)}
                                value={urlInformation.contact.phone}
                              />
                              <Input 
                                type="text"
                                placeHolder="Enter the address"
                                label="Address"
                                className="mt-2"
                                name="address"
                                onChange={(e) => handleUrlInformation(e)}
                                value={urlInformation.contact.address}
                              />
                            </div>
                          ):(
                            null
                          )
                        )
                      )
                    )
                  )
                )
              )}
              <p className="text-sm mt-2 text-[red]" >{error}</p>
              <Button
                isLoading={isLoading}
                onClick={createQRCode}
                text="NEXT"
                disabled={data.name.length == 0 || data.name.length > 18 || !isUrlInformationValid(urlInformation, qrCodeType)}
                className="mt-[34px]"
              />
            </div>
          ):(
            <div>
              <p className="mt-6">Type</p>
              <div className="flex justify-around">
                <Select
                  sx={{ backgroundColor: '#f', fontFamily: 'Inter', height: "40px", color: 'gray', ":hover ": { backgroundColor: '#fafafa' } }} 
                  onChange={(e, value) => setQrCodeType(value as QRCodeTypeSchema)} 
                  value={qrCodeType} 
                  className="text-[#FBFCFE] pr-6 mt-2 w-full bg-[white]"
                >
                  <Option sx={{ fontFamily: 'Inter' }} value="url">
                    <FaLink color="#7ed856"/>
                    <p>Open a Web Page</p>
                  </Option>
                  <Option sx={{ fontFamily: 'Inter' }} value="email">
                    <FaEnvelope color="#7ed856"/>
                    <p>Send an E-Mail</p>
                  </Option>
                  <Option sx={{ fontFamily: 'Inter' }} value="call">
                    <FaPhone color="#7ed856"/>
                    <p>Make a Phone call</p>
                  </Option>
                  <Option sx={{ fontFamily: 'Inter' }} value="sms">
                    <FaSms color="#7ed856"/>
                    Send a Text Message
                  </Option>
                </Select>
              </div>
              <Input 
                onChange={(e) => setData({ ...data, name: e.target.value })} 
                type="text"
                placeHolder="My QR Code"
                name="name"
                className="mt-2"
                label="Name"
                value={data.name}
              />
              <Button
                onClick={() => setReadyToCreate(true)}
                text="NEXT"
                disabled={data.name.length == 0 || data.name.length > 18}
                className="mt-[34px]"
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateModal;
