import { ReactElement } from "react";

interface AchievementsCardProps {
  title: string;
  content: string;
  icon: ReactElement<any, any>;
}

const AchievementsCard: React.FC<AchievementsCardProps> = ( props ) => {
  return (
    <div className="hover:mt-2 transition-all">
      <div className="shadow-md hover:bg-[#fafafa] transition-all border mb-4 pt-6 pb-6 p-14 rounded-2xl">
        <div className="w-full flex text-[#7ed856] justify-around">
          {props.icon}
        </div>
        <p className="text-3xl mt-4 text-center text-[#2C2C2C] font-bold">{props.content}</p>
        <p className="text-md mt-4 text-center font-medium">{props.title}</p>
      </div>
    </div>
  );
}

export default AchievementsCard;