import { useEffect, useRef, useState } from "react";
import { CgColorBucket, CgInfo } from "react-icons/cg";
import { CiClock1 } from "react-icons/ci";
import { LuQrCode } from "react-icons/lu";
import { FaEnvelope, FaLink, FaPhone, FaPoll, FaSms } from "react-icons/fa";
import { FaCamera } from "react-icons/fa6";

import { 
  useNavigate, 
  useSearchParams 
} from "react-router-dom";
import { 
  BASE_URL, 
  eraseCookie, 
  getUser, 
  isEmail, 
  isLoggedIn 
} from "../../utils";
import { 
  MdBusiness, 
  MdPeople, 
  MdSend 
} from "react-icons/md";
import { 
  PlanSchema, 
  QRCodeTypeSchema, 
  UserSchema 
} from "../../types";

import axios from "axios";
import AchievementsCard from "../../components/achievements_card";
import Plan from "../../components/plan";
import GetStartedModal from "../../components/modals/get_started";
import PurchaseModal from "../../components/modals/purchase";
import WorkingModal from "../../components/modals/working";
import Footer from "../../components/footer";
import WaitingListModal from "../../components/modals/waiting_list";
import FeatureCard from "../../components/feature_card";
import { TypeAnimation } from "react-type-animation";
import { Option, Select } from "@mui/joy";

const Homepage = () => {

  const [getStartedModal, setGetStartedModal] = useState({ visible: false, planToBuy: 0, canSignup: false, canSignin: false, passwordRecoveryCode: "" });
  const [waitingListEmail, setWaitingListEmail] = useState<string>("")
  const [waitingListModalVisible, setWaitingListModalVisible] = useState(false)
  const [purchaseModalVisible, setPurchaseModalVisible] = useState(false)
  const [analytics, setAnalytics] = useState<any>({})
  const [isWorking, setIsWorking] = useState(false)
  const [qrCodeType, setQRCodeType] = useState<QRCodeTypeSchema | null>(null)
  const [plans, setPlans] = useState<any>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [error, setError] = useState<string | undefined>(undefined)
  const [userPlan, setUserPlan] = useState<any>({})
  const [loggedIn, setLoggedIn] = useState(false)
  const navigate = useNavigate()
  const pricingRef = useRef<HTMLDivElement>(null)


  const getAnalytics = () => {
    axios.get(BASE_URL + "/users/analytics")
    .then(res => {
      setAnalytics(res.data.param)
    })
    .catch(err => {
      
    })
	}

  const getPlans = () => {
    axios.get(BASE_URL + "/plans")
    .then(res => {
      setPlans(res.data.param)
    })
    .catch(err => {
      
    })
	}

  const getUserPlan = async (userId: number) => {
    axios.get(BASE_URL + "/plans/user/" + userId)
    .then(res => {
      setUserPlan(res.data.param)
    })
    .catch(err => {
      
    })
	}

  const handleAuth = async () => {
    const loggedIn = await isLoggedIn()
    if (loggedIn) {
      const user: UserSchema | any = await getUser()
      getUserPlan(user.user_id)
    }
    setLoggedIn(loggedIn)
  }

  const getWorking = async () => {
    await axios.get(BASE_URL)
    .then(res => {
      setIsWorking(res.data.working)
    })
    .catch(err => {

    })
  }

  useEffect(() => {
    getWorking()
    handleAuth()
    getAnalytics()
    getPlans()
  }, [])

  const handleClick = () => {
    pricingRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const joinWaitingList = () => {
    axios.post(BASE_URL + "/waiting-list/", { email: waitingListEmail })
    .then(res => {
      setWaitingListEmail("")
      setWaitingListModalVisible(true)
    })
    .catch(err => {
      
    })
  }

  const getStarted = (planId: number) => {
    if (qrCodeType == null) {
      return
    }
    setGetStartedModal({ 
      visible: true, 
      planToBuy: planId, 
      canSignup: true,
      canSignin: false, 
      passwordRecoveryCode: ""
    })
  }

  const recoverPassword = (passwordRecoveryCode: string) => {
    setGetStartedModal({ 
      visible: true, 
      canSignup: false, 
      planToBuy: 0, 
      canSignin: false, 
      passwordRecoveryCode: passwordRecoveryCode
    })
  }

  const signin = () => {
    setGetStartedModal({ 
      visible: true, 
      canSignup: false, 
      planToBuy: 0, 
      canSignin: true,
      passwordRecoveryCode: "" 
    })
  }

  useEffect(() => {
    if (searchParams.get("code")) {
      recoverPassword(searchParams.get("code") || "")
    }
    if (searchParams.get("ref") == "pricing") {
      pricingRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (searchParams.get("ref") == "purchase") {
      setPurchaseModalVisible(true)
    }
  },[searchParams])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(undefined)
      }, 4000)
    }
  }, [error])

  return (
    <div className="w-screen h-screen">
      <GetStartedModal
        passwordRecoveryCode={getStartedModal.passwordRecoveryCode} 
        canSignin={getStartedModal.canSignin}
        planToBuy={getStartedModal.planToBuy}
        canSignup={getStartedModal.canSignup} 
        qrCodeType={qrCodeType || "url"}
        onClose={() => setGetStartedModal({ visible: false, canSignup: false, planToBuy: 0, canSignin: false, passwordRecoveryCode: "" })} 
        visible={getStartedModal.visible}
      />
      <WaitingListModal visible={waitingListModalVisible} onClose={() => setWaitingListModalVisible(false)} />
      <PurchaseModal onClose={() => setPurchaseModalVisible(false)} visible={purchaseModalVisible} />
      <WorkingModal visible={isWorking}/>
      <div className="top-0 h-24 flex p-14 md:pr-14 pr-10 bg-[#7ed856] items-center justify-between w-full">
        <div className="flex md:ml-2 ml-[-24px] items-center">
          <img width={42} src={require("../../images/logo4.png")} alt="" />
          <p className="ml-1 text-[white] text-lg">liddo</p>
        </div>
        <div>
          {loggedIn ? (
            <button className="hover:bg-transparent border-[2px] border border-white hover:text-[white] p-2 rounded-xl w-24 transition-all font-medium text-[#7ed856] bg-[white]" onClick={() => {eraseCookie("jwt-token"); navigate(0)}}>Logout</button>
          ):(
            <button className="hover:bg-transparent border-[2px] border border-white hover:text-[white] p-2 rounded-xl w-24 transition-all font-medium text-[#7ed856] bg-[white]" onClick={signin}>Login</button>
          )}
        </div>
      </div>
      <div className="flex w-full p-6 md:p-0 pb-24 pt-8 bg-[#7ed856] justify-around">
        <div>
          <div>
            <h1 className="md:text-[54px] leading-tight	font-extrabold text-center mt-14 text-[38px] text-[white] whitespace-no-wrap">
              It's finally time to
            </h1>
            <div className="flex justify-around items-center">
              <div className="md:flex md:gap-4 gap-1 items-center">
                <div className="flex max-w-[584px] justify-around">
                  <TypeAnimation
                    className='md:text-[54px] text-[38px] md:h-[80.4px] h-[54.4px] text-[white] font-extrabold'
                    sequence={[
                      "Create",
                      2140,
                      "Customize",
                      1000,
                      "Track",
                      1000,
                      () => {
                        return
                      }
                    ]}
                    wrapper="div"
                    speed={64}
                    cursor={false}
                    repeat={Infinity}
                  />
                </div>
                <h1 className="md:text-[54px] leading-tight	font-extrabold text-center text-[38px] text-[white] whitespace-no-wrap">QR Codes.</h1>
              </div>
            </div>
          </div>
          <div className="flex justify-around">
            <p className="mt-6 max-w-[514px] md:text-lg text-[white] text-center text-md">
              We empower businesses and individuals to generate custom QR codes and gain real-time insights for every scan.
            </p>
          </div>
          <div className="flex pb-24 md:w-[564px] justify-around">
            {
              loggedIn ? (
                <div className="md:flex w-full h-14 justify-around mt-8">
                  <div className="flex md:w-3/4 w-full h-full">
                    <button 
                      onClick={() => navigate("/dashboard")} 
                      className="rounded-full hover:bg-transparent border-[2px] border border-white hover:text-[white] w-full font-semibold transition-all text-[#7ed856] bg-[white] text-sm p-2 md:p-4">
                        GO TO DASHBOARD
                      </button>
                  </div>
                </div>
              ):(
                <div className="flex w-full justify-around mt-8">
                  <div className="bg-[white] p-2 z-[0] flex border pl-2 rounded-full">
                    <Select
                      placeholder="Create a QR Code to ..." 
                      sx={{ 
                        backgroundColor: 'transparent', 
                        fontFamily: 'Inter', 
                        color: 'gray', 
                        ":hover ": { backgroundColor: '#fafafa', color: 'gray' } 
                      }} 
                      variant="plain" 
                      onChange={(e, value) => setQRCodeType(value as QRCodeTypeSchema)} 
                      value={qrCodeType}
                      className="md:ml-2 text-[gray] pr-6 w-64 bg-[white] ml-1"
                    >
                      <Option value="url">
                        <FaLink color="#7ed856"/>
                        <p>Open a Web Page</p>
                      </Option>
                      <Option value="email">
                        <FaEnvelope color="#7ed856"/>
                        <p>Send an E-Mail</p>
                      </Option>
                      <Option value="call">
                        <FaPhone color="#7ed856"/>
                        <p>Make a Phone call</p>
                      </Option>
                      <Option value="sms">
                        <FaSms color="#7ed856"/>
                        Send a Text Message
                      </Option>
                    </Select>
                    <button onClick={handleClick} className="rounded-full transition-all hover:bg-opacity-60 ml-2 font-semibold bg-[#7ed856] text-[white] text-sm p-[12.4px]">
                      <p className="md:block hidden">CREATE QR CODE</p>
                      <p className="md:hidden"><MdSend/></p>
                    </button>
                  </div>
                </div>
              )
            }
            {/*<div className="flex w-full justify-around mt-8">
              {<div className="bg-[white] p-2 z-[0] flex border justify-around items-center pl-2 rounded-full">
                <input 
                  type="text"
                  name=""
                  className="border-none outline-none h-8 pl-6 w-64"
                  placeholder="Enter your email"
                  onChange={(e) => setWaitingListEmail(e.target.value)} 
                  value={waitingListEmail}
                />
                <button disabled={!isEmail(waitingListEmail)} onClick={joinWaitingList} className="rounded-full disabled:bg-opacity-60 transition-all hover:bg-opacity-60 ml-2 font-semibold bg-[#7ed856] text-[white] text-sm p-[12.4px]">
                  <p className="md:block hidden">JOIN WAITING LIST</p>
                  <p className="md:hidden"><MdSend/></p>
                </button>
              </div>}
            </div>*/}
            <p className="text-sm text-[red] mt-1">{error}</p>
          </div>
        </div>
      </div>
      <div style={{ borderRadius: '10000px / 1040px' }} className="w-screen absolute md:mt-[-84px] z-0 mt-[-124px] bg-[#7ed856] h-40">

      </div>
      <div className="w-full pt-40 flex p-8 justify-around">
        <div>
          <div className="max-w-[840px]">
            <h1 className="text-center text-[#2C2C2C] text-4xl font-extrabold">Some Features</h1>
            <p className="text-center text-[gray] text-md mt-2">
              These are the most important features of Bliddo. We are constantly working to improve and add new features to our platform.
            </p>
          </div>
          <div className="mt-10">
            <div className="lg:flex gap-4">
              <FeatureCard
                title="QR Code Capabilities"
                imageWidth="245px"
                imageMarginLeft="114px"
                maxWidth="432px"
                image={require("../../images/qrcodetypes.png")}
                description="Create QR Codes to open web pages, make calls, send emails, or text messages. Enhance user convenience."
                icon={<LuQrCode size={54} color="#7ed856"/>}
              />
              <FeatureCard
                title="Scan Analytics"
                imageWidth="214px"
                imageMarginLeft="94px"
                maxWidth="432px"
                image={require("../../images/analytics.png")}
                description="Track daily, weekly, monthly, and yearly scans. Gain insights and make informed decisions."
                icon={<CiClock1 size={54} color="#7ed856"/>}
              />
            </div>
            <div className="lg:flex lg:mt-0 mt-4 gap-4">
              <FeatureCard
                title="Customizable QR Codes"
                imageWidth="234px"
                imageMarginLeft="94px"
                maxWidth="410px"
                image={require("../../images/customization.png")}
                description="Change colors and add logos to your QR Codes. Align them with your brand or just your needs."
                icon={<CgColorBucket size={54} color="#7ed856"/>}
              />
              <FeatureCard
                title="User Insights"
                imageWidth="245px"
                imageMarginLeft="94px"
                maxWidth="454px"
                image={require("../../images/usersinformation.png")}
                description="See details of users who scan your QR Codes, including location and device. Optimize your strategies."
                icon={<CgInfo size={54} color="#7ed856"/>}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-40 flex p-8 justify-around">
        <div>
          <div className="flex justify-around">
            <div>
              <div className="max-w-[840px]">
                <h1 className="text-center text-4xl text-[#2C2C2C] font-extrabold">What's Bliddo?</h1>
                <p className="text-center text-[gray] text-md mt-2">
                  Simple video to show you the basics of Bliddo and how it works.
                </p>
              </div>
              <div className="flex mt-8 justify-around w-full">
                <iframe className="md:w-[840px] rounded-2xl md:h-[480px] h-[254px] w-[324px]" 
                  src="https://www.youtube.com/embed/yotKa1CvpaE"> 
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-40 flex p-8 justify-around">
        <div>
          <div className="flex justify-around">
            <div className="max-w-[840px]">
              <h1 className="text-center text-[#2C2C2C] text-4xl font-extrabold">Achievements</h1>
              <p className="text-center text-[gray] text-md mt-2">
                Some of our achievements so far. We are committed to helping you achieve your goals through precise and reliable QR code tracking.
              </p>
            </div>
          </div>
          <div className="md:flex mt-14 md:h-40 gap-8">
            <AchievementsCard
              title="REGISTERED USERS"
              content={analytics.users}
              icon={<MdPeople color="#7ed856" size={44}/>}
            />
            <AchievementsCard
              title="CREATED QR CODES"
              content={analytics.qrcodes}
              icon={<FaPoll color="#7ed856" size={44}/>}
            />
            <AchievementsCard
              title="SCANNED QR CODES"
              content={analytics.accesses}
              icon={<FaCamera color="#7ed856" size={44}/>}
            />
            <AchievementsCard
              title="HELPED BUSINESSES"
              content={analytics.business_helped}
              icon={<MdBusiness color="#7ed856" size={44}/>}
            />
          </div>
        </div>
      </div>
      <div ref={pricingRef} className="w-full pt-40 flex p-8 justify-around">
        <div>
          <div className="flex justify-around">
            <div className="max-w-[640px]">
              <div className="flex w-full justify-around">
                <div className="rounded-full bg-[#7ed856] font-semibold text-xs p-1 pl-2 pr-2 text-[white]">
                  <p>NEW PLANS ARE COMING...</p>
                </div>
              </div>
              <h1 className="text-center mt-2 text-4xl text-[#2C2C2C] font-extrabold">Pricing</h1>
              <p className="text-center text-[gray] text-md mt-2">
                Unlock the full potential of our platform with our Premium Plan, 
                designed for users who demand more power and flexibility.
              </p>
            </div>
          </div>
          <div className="md:flex justify-around mt-14 gap-14">
            {plans.map((plan: PlanSchema) => (
              <Plan
                key={plan.plan_id}
                purchasable={qrCodeType != null}
                plan={plan}
                loggedIn={loggedIn}
                ownPlan={userPlan.plan_id === plan.plan_id}
                onGetStarted={() => getStarted(plan.plan_id)}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Homepage;