import React, { ReactElement } from "react";

interface FeatureCardProps {
  title: string;
  imageWidth: string;
  imageMarginLeft: string;
  maxWidth: string;
  image: any;
  description: string;
  icon: ReactElement<any, any>;
}

const FeatureCard: React.FC<FeatureCardProps> = ( props ) => {
  return (
    <div style={{ maxWidth: props.maxWidth }} className="rounded-2xl lg:h-[314px] h-[334px] group hover:bg-[#fafafa] md:w-auto border mt-4 transition-all shadow-md p-6">
      <div className="absolute md:ml-[140px] ml-[64px] w-[184px] md:w-[214px] mt-4">
        <img draggable={false} className="group-hover:opacity-40 transition-all opacity-60 w-full h-full" src={props.image} alt="" />
      </div>
      <div draggable={false} className="group-hover:mt-[114px] transition-all mt-[124px]">
        {props.icon}
        <p className="font-semibold mt-2 text-[#2C2C2C] text-xl">{props.title}</p>
        <p className="mt-2 text-sm text-[gray]">{props.description}</p>
      </div>
    </div>
  );
}

export default FeatureCard;