import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../button";
import ModalClose from "../../modal_close";

interface PurchaseModalProps {
  visible: boolean;
  onClose: () => void;
}

const PurchaseModal: React.FC<PurchaseModalProps> = ( props ) => {

  const navigate = useNavigate()

  if (!props.visible) {
    return null
  }

  return (
    <div>
      <div className="w-screen z-10 h-screen fixed flex justify-around items-center bg-[black] bg-opacity-30">
        <div className="bg-[white] w-80 top-0 p-6 rounded-xl">
          <div className="flex justify-between">
            <div></div>
            <ModalClose onClick={props.onClose}/>
          </div>
          <img width={40.4} src={require("../../../images/logo2.png")} alt="" />
          <h1 className="font-semibold max-w-64 mt-2 text-xl">Thank you for purchasing our Plan.</h1>
          <p className="max-w-64 mt-4 text-sm">You successfully purchased our Plan, start enjoying our service now.</p>
          <Button
            onClick={() => {
              navigate("/dashboard")
            }}
            className="mt-6 w-full"
            text="GET STARTED"
          />
        </div>
      </div>
    </div>
  );
}

export default PurchaseModal
