import React from "react";

interface LoadingProps {
  full_screen: boolean;
}

const Loading: React.FC<LoadingProps> = ( props ) => {

  if (props.full_screen) {
    return (
      <div className="w-screen top-0 left-0 h-screen absolute bg-[black] bg-opacity-30 flex justify-center items-center">
        <img className="loader w-24" src={require("../../images/logo2.png")} alt="" />
      </div>
    )
  }

  return (
    <img className="loader w-24" src={require("../../images/logo2.png")} alt="" />
  )
}

export default Loading;