import { useEffect, useState } from "react"
import { BASE_URL, getCookie, getUser, isLoggedIn } from "../../utils"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { ChartDataSchema, PlanSchema, QRCodeSchema, TextDataSchema, UserSchema } from "../../types"
import Loading from "../../components/loading"
import { BarChart, PieChart } from "@mui/x-charts"
import { MdAdd, MdDashboardCustomize, MdDelete, MdDeleteOutline, MdDone, MdDownload, MdInfo } from "react-icons/md"
import { FaDownload } from "react-icons/fa6"
import CreateModal from "../../components/modals/create"
import { CHART_DATA, PLAN, QRCODE, TEXT_DATA } from "../../constants"
import CustomQRCode from "../../components/qrcode"
import { Option, Select } from "@mui/joy"
import Footer from "../../components/footer"
import DashboardCard from "../../components/dashboard_card"
import WorldMap from "react-svg-worldmap"
import CustomCheckbox from "../../components/custom_checkbox"
import { LuDelete } from "react-icons/lu"
import DeleteQRCodeModal from "../../components/modals/delete_qrcode"

const Dashboard: React.FC = () => {

  const navigate = useNavigate()
  const [deleteQRCodeModal, setDeleteQRCodeModal] = useState({ visible: false, qrCodeId: 0, qrCodeName: "" })
  const [createModal, setCreateModal] = useState({ visible: false, customization: false})
  const [showMap, setShowMap] = useState(false)
  const [devKeyModal, setDevKeyModal] = useState(false)
  const [devices, setDevices] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState<string>("all")
  const [locationGraph, setLocationGraph] = useState<any>([])
  const [countries, setCountries] = useState<string[]>([])
  const [user, setUser] = useState<UserSchema>()
  const [userPlan, setUserPlan] = useState<PlanSchema>(PLAN)
  const [filter, setFilter] = useState<string>("daily")
  const [chartData, setChartData] = useState<ChartDataSchema>(CHART_DATA)
  const [textData, setTextData] = useState<TextDataSchema>(TEXT_DATA)
  const [qrcodes, setQrcodes] = useState<QRCodeSchema[]>([])
  const [selectedQrcode, setSelectedQrcode] = useState<QRCodeSchema>(QRCODE)

  useEffect(() => {
    auth()
  }, [])
  
  const getQrcodes = async () => {
    await axios.get(BASE_URL + '/qrcodes/user', {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    })
    .then(res => {
      setQrcodes(res.data.param)
      setSelectedQrcode(res.data.param[0])
      changeQrCode(res.data.param[0].qrcode_id)
    })
    .catch(err => {

    })
  }
  
  const auth = async () => {
    if (!(await isLoggedIn())) {
      navigate("/")
      return
    }
    setUser(await getUser() as UserSchema)
    getQrcodes()
  }

  const downloadQRCode = async () => {
    const linkSource = "data:image/png;base64," + selectedQrcode.image_src;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = selectedQrcode.name + " - QRCode.png";
    downloadLink.click();
  }

  const generateMapData = () => {
    let res: any = []
    locationGraph.forEach((location: any) => {
      console.log(location)
      res.push({
        country: location.country_code,
        value: location.value
      })
    })
    return res
  }

  const changeQrCode = async (qrCodeId: number) => {
    setIsLoading(true)
    await axios.get(BASE_URL + '/accesses/' + qrCodeId + "?filter=" + filter + "&country=" + selectedCountry)
    .then(res => {
      setTextData({
        scans: res.data.param.scans,
        old_scans: res.data.param.old_scans,
        label: res.data.param.label,
        daily_scans: res.data.param.daily_scans,
        percentage: res.data.param.plan.percentage,
        double_scans: res.data.param.double_scans,
        old_double_scans: res.data.param.old_double_scans,
      })
      setChartData({
        x: res.data.param.x,
        y: res.data.param.y
      })
      setDevices(res.data.param.devices)
      setUserPlan(res.data.param.plan)
      setCountries(res.data.param.location.countries)
      setLocationGraph(res.data.param.location.places)
      setIsLoading(false)
    })
    .catch(err => {
      
    }) 
  }

  useEffect(() => {
    if (selectedQrcode) {
      changeQrCode(selectedQrcode.qrcode_id)
    }
  }, [selectedQrcode, filter, selectedCountry])


  return (
    isLoading ? (
      <Loading full_screen/>
    ):(
      <>
        <CreateModal onClose={(trigger) => {
          if (trigger) {
            getQrcodes()
          }
          setCreateModal({ visible: false, customization: false})
        }} qrCode={selectedQrcode} customizable={userPlan.customizable} customization={createModal.customization} visible={createModal.visible}/>
        <DeleteQRCodeModal
          onClose={() => {
            setDeleteQRCodeModal({ visible: false, qrCodeId: 0, qrCodeName: "" })
            getQrcodes()
          }}
          visible={deleteQRCodeModal.visible} 
          qrCodeName={deleteQRCodeModal.qrCodeName}
          qrCodeId={deleteQRCodeModal.qrCodeId}
        />
        <div className="md:flex hidden w-screen h-screen p-6 bg-[#f4f5f7] justify-around items-center">
          <div className="bg-[white] p-[24px] bg-[white] shadow-sm rounded-lg ">
            <p className="text-[#7ed856] font-semibold">{userPlan.name}</p>   
            <h1 className="font-semibold mt-[-6px] text-[#2C2C2C] text-2xl" >Dashboard</h1>
            <div className="flex mt-[24px]">
              <div>
                <div className="w-full flex gap-2 p-[2.4px] pl-2 border rounded-lg bg-[white] shadow-sm pr-1">
                  <div className="flex items-center">
                    <button onClick={() => setCreateModal({ visible: true, customization: false })} className="p-1 bg-[#7ed856] text-[white] transition-all rounded-full"><MdAdd/></button>
                  </div>
                  <Select
                    sx={{ backgroundColor: 'transparent', fontFamily: 'Inter', ":hover": { backgroundColor: '#fafafa' } }}
                    variant="plain"
                    onChange={(e: any, value: number | null) => {
                      const qrcode = qrcodes.filter(qrcode => qrcode.qrcode_id == value)[0]
                      setSelectedQrcode(qrcode)
                    }}
                    value={selectedQrcode.qrcode_id} 
                    className="w-full cursor-pointer bg-[transparent]"
                  >
                    {qrcodes.map((qrcode: QRCodeSchema) => (
                      <Option 
                        sx={{ fontFamily: 'Inter' }}
                        className="flex justify-between pl-1 pr-1"
                        key={qrcode.qrcode_id} 
                        value={qrcode.qrcode_id}>
                          <p>{qrcode.name}</p>
                          {qrcodes.length > 1 ? (
                            <button onClick={() => setDeleteQRCodeModal({ visible: true, qrCodeId: qrcode.qrcode_id, qrCodeName: qrcode.name })}>
                              <MdDeleteOutline color="#ff5d5c" className="hover:opacity-40 transition-all" size={18}/>
                            </button>
                          ): null}
                        </Option>
                    ))}
                  </Select>
                </div>
                <div className="mt-[24px] bg-[white] w-[284px] w-full pb-[42px] border rounded-lg bg-[white] shadow-sm">
                  <div className="flex justify-around">
                    <div className="w-[254px] p-6">
                      <CustomQRCode
                        watermark={userPlan.watermark}
                        customizable={userPlan.customizable}
                        logo_url={selectedQrcode.logo_url}
                        customLogo={selectedQrcode.custom_logo}
                        flow_url={selectedQrcode.flow_url}
                        showOptions
                        qrcode_type={selectedQrcode.qrcode_type}
                        image_src={selectedQrcode.image_src}
                        bgColor={selectedQrcode.bg_color}
                        borderColor={selectedQrcode.border_color}
                        fgColor={selectedQrcode.fg_color}
                        size={214}
                        name={selectedQrcode.name}
                        onDownload={downloadQRCode}
                        onCustomizationClicked={() => setCreateModal({ visible: true, customization: true })}
                        onDevKeyClicked={() => setDevKeyModal(true)}
                      />
                    </div>
                  </div>
                  <div className="mt-2 pr-8 pl-8">
                    <div className="mt-2">
                      <p className="text-md max-w-64 text-[#2C2C2C] font-semibold">URL</p>
                      <a className="underline text-sm" href={selectedQrcode.url}>{
                        selectedQrcode.url.length > 24 ? 
                        selectedQrcode.url.substring(0,24) + "..." : 
                        selectedQrcode.url}</a>
                    </div>
                    <div className="mt-2">
                      <p className="text-md max-w-64 text-[#2C2C2C] font-semibold">Usage</p>
                      <div className="flex items-center gap-2">
                        <p className="text-md mt-1">{textData.daily_scans}<span className="text-md opacity-60 text-[gray]">/{userPlan.max_scans}</span> SCANS</p>
                        <button onClick={() => navigate("/?ref=pricing")} className="mt-1 text-[#7ed856]">
                          <MdInfo/>
                        </button>
                      </div>
                      <div className="w-full h-[10.4px] rounded-full mt-1 border shadow-sm">
                        <div className="bg-[#7ed856] rounded-full h-full" style={{ width: textData.percentage + "%" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-8">
                <div className="flex gap-[14px]">
                  <Select
                    sx={{ backgroundColor: 'transparent', fontFamily: 'Inter', ":hover": { backgroundColor: '#fafafa' } }}
                    onChange={(e: any, value: string | null) => setFilter(value || "")} 
                    value={filter} 
                    className="w-full h-11 cursor-pointer bg-[transparent]"
                  >
                    <Option sx={{ fontFamily: 'Inter' }} value="daily">Daily</Option>
                    <Option sx={{ fontFamily: 'Inter' }} value="weekly">Weekly</Option>
                    <Option sx={{ fontFamily: 'Inter' }} value="monthly">Monthly</Option>
                    <Option sx={{ fontFamily: 'Inter' }} value="yearly">Yearly</Option>
                  </Select>
                  {!showMap ? (
                    <Select
                      sx={{ backgroundColor: 'transparent', fontFamily: 'Inter', ":hover": { backgroundColor: '#fafafa' } }}
                      onChange={(e: any, value: string | null) => setSelectedCountry(value || "")}
                      value={selectedCountry}
                      className="w-full h-11 cursor-pointer bg-[transparent]"
                    >
                      <Option sx={{ fontFamily: 'Inter' }} value={"all"}> Worldwide</Option>
                      {countries.map((country: any) => (
                        <Option sx={{ fontFamily: 'Inter' }} key={country.country_code} value={country.country_code}>{country.country_name}</Option>
                      ))}
                    </Select>
                  ):(null)}
                </div>
                <div className="flex gap-6">
                  {showMap ? (
                    <div className="mt-[24px] bg-[white] p-4 rounded-lg shadow-sm border">
                      <div className="mb-2 flex justify-between">
                        <h1 className="font-medium text-[#2C2C2C] text-md">Locations</h1>
                        <div className="flex gap-2 items-center">
                          <p className="text-sm">Show map</p>
                          <CustomCheckbox checked={showMap} onChange={(e) => setShowMap(!showMap)}/>
                        </div>
                      </div>
                      <WorldMap
                        color="#7ed856"
                        value-suffix="people"
                        size={558}
                        data={generateMapData()}
                      />
                    </div>
                  ):(
                    <>
                      <div className="gap-y-6">
                        <div className="mt-[24px] bg-[white] p-4 rounded-lg shadow-sm border">
                          <div className="mb-2">
                            <h1 className="font-medium text-[#2C2C2C] text-md">Overview</h1>
                          </div>
                          <BarChart
                            colors={["#7ed856"]}
                            xAxis={[{ data: chartData.x, scaleType: 'band' }]}
                            series={[
                              {
                                data: chartData.y,
                              }
                            ]}
                            width={500}
                            height={284}
                          />
                        </div>
                        <div className="flex gap-6">
                          <DashboardCard
                            title="Total scans"
                            scans={textData.scans}
                            old_scans={textData.old_scans}
                            label={textData.label}
                          />
                          <DashboardCard
                            title="Double scans"
                            scans={textData.double_scans}
                            old_scans={textData.old_double_scans}
                            label={textData.label}
                          />
                        </div>
                      </div>
                      <div className="gap-y-6">
                        <div className="mt-[24px] bg-[white] p-4 rounded-lg shadow-sm border">
                          <div className="mb-2 flex gap-2 justify-between">
                            <h1 className="font-medium text-[#2C2C2C] text-md">Locations</h1>
                            {selectedCountry == "all" ? (
                              <div className="flex gap-2 items-center">
                                <p className="text-sm">Show map</p>
                                <CustomCheckbox checked={showMap} onChange={(e) => setShowMap(!showMap)}/>
                              </div>
                            ): null}
                          </div>
                          <div className="pl-[61px]">
                            <PieChart
                              series={[
                                {
                                  data: locationGraph
                                },
                              ]}
                              colors={["#6dc34f","#8ae369","#6abf42","#86d169","#77d04f","#7ed856","#6fcf43","#78d050","#82cd62","#70cc4d","#7fce55","#76d05b"]}
                              width={240}
                              margin={{ top: 10, bottom: 10, left: 0, right:114 }}
                              slotProps={{
                                legend: {
                                  hidden: true
                                }
                              }}
                              height={170}
                            />
                          </div>
                        </div>
                        <div className="mt-[14px] bg-[white] p-4 flex rounded-lg shadow-sm border">
                          <div className="mb-2">
                            <h1 className="font-medium text-[#2C2C2C] text-md">Devices</h1>
                          </div>
                          <PieChart
                            series={[
                              {
                                data: devices
                              },
                            ]}
                            colors={["#6dc34f","#8ae369","#6abf42","#86d169","#77d04f","#7ed856","#6fcf43","#78d050","#82cd62","#70cc4d","#7fce55","#76d05b"]}
                            width={240}
                            height={200}
                            margin={{ top: 10, bottom: 10, left: 0, right:114 }}
                            slotProps={{
                              legend: {
                                direction: "column",
                                position: {
                                  vertical: 'middle',
                                  horizontal: 'right',
                                },
                                itemMarkWidth: 18,
                                itemMarkHeight: 4,
                                markGap: 4,
                                itemGap: 8,
                                labelStyle: {
                                  fontSize: 11.4
                                },
                              }
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden w-screen h-screen overflow-y-scroll p-6 bg-[#f4f5f7]">    
          <p className="text-[#7ed856] font-semibold">{userPlan.name}</p>   
          <h1 className="font-semibold text-2xl mt-[-6px]" >Dashboard</h1>
          <div className="mt-[14px]">
            <div className="mt-[24px] bg-[white] w-[284px] w-full p-6 pb-[42px] border rounded-lg bg-[white] shadow-sm">
              <div className="w-full flex gap-2 p-[2px] pl-2 border rounded-lg bg-[white] shadow-sm pr-1">
                <div className="flex items-center">
                  <button onClick={() => setCreateModal({ visible: true, customization: false })} className="p-1 bg-[#7ed856] text-[white] transition-all rounded-full"><MdAdd/></button>
                </div>
                <Select
                  sx={{ backgroundColor: 'transparent', fontFamily: 'Inter', ":hover": { backgroundColor: '#fafafa' } }}
                  variant="plain"
                  onChange={(e: any, value: number | null) => {
                    const qrcode = qrcodes.filter(qrcode => qrcode.qrcode_id == value)[0]
                    setSelectedQrcode(qrcode)
                  }}
                  value={selectedQrcode.qrcode_id} 
                  className="w-full cursor-pointer bg-[transparent]"
                >
                  {qrcodes.map((qrcode: QRCodeSchema) => (
                    <Option 
                      sx={{ fontFamily: 'Inter' }}
                      className="flex justify-between pl-1 pr-1"
                      key={qrcode.qrcode_id} 
                      value={qrcode.qrcode_id}
                    >
                      <p>{qrcode.name}</p>
                      {qrcodes.length > 1 ? (
                        <button onClick={() => setDeleteQRCodeModal({ visible: true, qrCodeId: qrcode.qrcode_id, qrCodeName: qrcode.name })}>
                          <MdDeleteOutline color="#ff5d5c" className="hover:opacity-40 transition-all" size={18}/>
                        </button>
                      ): null}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="mt-6">
                <div className="flex gap-6">
                  <button className="bg-[#7ed856] text-[white] text-2xl p-2 rounded-full" onClick={downloadQRCode} >
                    <FaDownload/>
                  </button>
                  <button className="bg-[#7ed856] text-[white] p-2 text-2xl rounded-full" onClick={() => setCreateModal({ visible: true, customization: true })} >
                    <MdDashboardCustomize/>
                  </button>
                </div>
                <div className="mt-6">
                  <div>
                    <p className="text-md max-w-64 text-[#2C2C2C] font-semibold">URL</p>
                    <a className="underline text-sm" href={selectedQrcode.url}>{
                      selectedQrcode.url.length > 24 ? 
                      selectedQrcode.url.substring(0,24) + "..." : 
                      selectedQrcode.url}</a>
                  </div>
                  <div className="mt-2">
                    <p className="text-md max-w-64 text-[#2C2C2C] font-semibold">Usage</p>
                    <div className="flex items-center gap-2">
                      <p className="text-sm mt-1">{textData.daily_scans}<span className="text-lg opacity-60 text-[gray]">/{userPlan.max_scans}</span> SCANS</p>
                      <button onClick={() => navigate("/?ref=pricing")} className="mt-1 text-[#7ed856]">
                        <MdInfo/>
                      </button>
                    </div>
                    <div className="w-full h-[10.4px] rounded-full mt-1 border shadow-sm">
                      <div className="bg-[#7ed856] rounded-full h-full" style={{ width: textData.percentage + "%" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="flex gap-[14px]">
                <Select
                  sx={{ backgroundColor: 'white', fontFamily: 'Inter', ":hover": { backgroundColor: '#fafafa' } }}
                  onChange={(e: any, value: string | null) => setFilter(value || "")} 
                  value={filter} 
                  className="w-full cursor-pointer bg-[transparent]"
                >
                  <Option sx={{ fontFamily: 'Inter' }} value="daily">Daily</Option>
                  <Option sx={{ fontFamily: 'Inter' }} value="weekly">Weekly</Option>
                  <Option sx={{ fontFamily: 'Inter' }} value="monthly">Monthly</Option>
                  <Option sx={{ fontFamily: 'Inter' }} value="yearly">Yearly</Option>
                </Select>
                <Select
                  sx={{ backgroundColor: 'white', fontFamily: 'Inter', ":hover": { backgroundColor: '#fafafa' } }}
                  onChange={(e: any, value: string | null) => setSelectedCountry(value || "")}
                  value={selectedCountry} 
                  className="w-full cursor-pointer bg-[transparent]"
                >
                  <Option sx={{ fontFamily: 'Inter' }} value={"all"}>Worldwide</Option>
                  {countries.map((country: any) => (
                    <Option sx={{ fontFamily: 'Inter' }} key={country.country_code} value={country.country_code}>{country.country_name}</Option>
                  ))}
                </Select>
              </div>
              <div>
                <div className="gap-y-6">
                  <div className="mt-[24px] bg-[white] p-4 rounded-lg shadow-sm border">
                    <div className="mb-2">
                      <h1 className="font-medium text-[#2C2C2C] text-md">Overview</h1>
                    </div>
                    <div style={{ marginLeft: "-34px" }}>
                      <BarChart
                        colors={["#7ed856"]}
                        xAxis={[{ data: chartData.x, scaleType: 'band' }]}
                        series={[
                          {
                            data: chartData.y,
                          }
                        ]}
                        width={384}
                        height={224}
                      />
                    </div>
                  </div>
                  <div className="flex gap-6">
                    <DashboardCard
                      title="Total scans"
                      scans={textData.scans}
                      old_scans={textData.old_scans}
                      label={textData.label}
                    />
                    <DashboardCard
                      title="Double scans"
                      scans={textData.double_scans}
                      old_scans={textData.old_double_scans}
                      label={textData.label}
                    />
                  </div>
                </div>
                <div className="gap-y-6">
                  <div className="mt-[24px] bg-[white] p-4 flex rounded-lg shadow-sm border">
                    <div className="mb-2">
                      <h1 className="font-medium text-[#2C2C2C] text-md">Locations</h1>
                    </div>
                    <PieChart
                      series={[
                        {
                          data: locationGraph
                        },
                      ]}
                      colors={["#6dc34f","#8ae369","#6abf42","#86d169","#77d04f","#7ed856","#6fcf43","#78d050","#82cd62","#70cc4d","#7fce55","#76d05b"]}
                      width={240}
                      margin={{ top: 10, bottom: 10, left: 0, right:114 }}
                      slotProps={{
                        legend: {
                          hidden: true
                        }
                      }}
                      height={200}
                    />
                  </div>
                  <div className="mt-[14px] bg-[white] p-4 flex rounded-lg shadow-sm border">
                    <div className="mb-2">
                      <h1 className="font-medium text-[#2C2C2C] text-md">Devices</h1>
                    </div>
                    <PieChart
                      series={[
                        {
                          data: devices
                        },
                      ]}
                      colors={["#6dc34f","#8ae369","#6abf42","#86d169","#77d04f","#7ed856","#6fcf43","#78d050","#82cd62","#70cc4d","#7fce55","#76d05b"]}
                      width={240}
                      height={200}
                      margin={{ top: 10, bottom: 10, left: 0, right:114 }}
                      slotProps={{
                        legend: {
                          direction: "column",
                          position: {
                            vertical: 'middle',
                            horizontal: 'right',
                          },
                          itemMarkWidth: 18,
                          itemMarkHeight: 4,
                          markGap: 4,
                          itemGap: 8,
                          labelStyle: {
                            fontSize: 11.4
                          },
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
          <div className="h-6"></div>
        </div>
      </>
    )
  )
}

export default Dashboard