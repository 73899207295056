import { Option, Select } from "@mui/joy";
import { PREFIXES } from "../../constants";
import Flag from "react-world-flags";
import { useEffect, useState } from "react";


interface PhonePrefixSelectorProps {
  onChange: (prefix: string) => void;
}

const PhonePrefixSelector: React.FC<PhonePrefixSelectorProps> = ( props ) => {

  const [selectedPrefix, setSelectedPrefix] = useState({ code: "US", prefix: "+1" });

  const handleChange = (prefix: string) => {
    for (const [key, value] of Object.entries(PREFIXES)) {
      if (value === prefix) {
        setSelectedPrefix({ code: key, prefix: value });
      }
    }
    props.onChange(prefix);
  }

  return (
    <div className="flex pl-2 border-[#CDD7E1] border rounded-lg bg-[#FBFCFE] items-center">
      <Flag className="w-6 rounded-sm" code={selectedPrefix.code} />
      <Select
        onChange={(e, value) => handleChange(value as string)} 
        name="prefix"
        sx={{ backgroundColor: 'transparent', border: 'none', fontFamily: 'Poppins', boxShadow: 'none', ":hover": { backgroundColor: '#fafafa' } }}
        value={selectedPrefix.prefix}
        className="w-[124px]"
      >
        {Object.entries(PREFIXES).map(prefix => (
          <Option sx={{ fontFamily: 'Poppins' }} className="gap-1" value={prefix[1]}>
            <Flag className="w-6 rounded-sm" code={prefix[0]} />
            <p>{prefix[1]}</p>
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default PhonePrefixSelector;