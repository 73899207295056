import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdDone } from "react-icons/md";
import { BASE_URL, getCookie } from "../../../utils";
import { FaCopy } from "react-icons/fa";
import ModalClose from "../../modal_close";
import Button from "../../button";
import { CgClose } from "react-icons/cg";

interface DevKeyModalProps {
  visible: boolean;
  onClose: () => void;
  qrCodeId: number;
  qrCodeName: string;
}

const DeleteQRCodeModal: React.FC<DevKeyModalProps> = ( props ) => {

  const [isLoading, setIsLoading] = useState(false)

  const deleteQRCode = async () => {
    setIsLoading(true)
    await axios.delete(BASE_URL + '/qrcodes/' + props.qrCodeId, {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    })
    .then(res => {
      props.onClose()
    })
    .catch(err => {
      
    })
    setIsLoading(false)
  }

  if (!props.visible) {
    return null
  }

  return (
    <div>
      <div className="w-screen z-10 top-0 left-0 h-screen absolute flex justify-around items-center bg-[black] bg-opacity-30">
        <div className="bg-[white] p-6 rounded-xl">
          <div className="flex justify-between">
            <div></div>
            <ModalClose onClick={props.onClose}/>
          </div>
          <img width={40.4} src={require("../../../images/logo2.png")} alt="" />
          <h1 className="font-semibold max-w-64 mt-2 text-xl">Delete QR Code '{props.qrCodeName}'</h1>
          <p className="max-w-72 text-sm mt-1">
            Are you sure you want to delete this qr code? This action cannot be undone.
          </p>
          <ul className="mt-4">
            <li className="flex items-center font-medium gap-2">
              <CgClose color="#ff5d5c"/>
              QR Code will be deleted.
            </li>
            <li className="flex items-center font-medium gap-2">
              <CgClose color="#ff5d5c"/>
              All the scans will be deleted
            </li>
          </ul>
          <Button
            onClick={deleteQRCode}
            className="mt-6"
            text="DELETE"
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default DeleteQRCodeModal;
