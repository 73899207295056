import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { QRCodeTypeSchema, UrlInformationSchema } from "../types";

//export const BASE_URL = 'http://localhost:5000';
export const BASE_URL = 'https://bliddo.pythonanywhere.com';

export const isLoggedIn = async () => {
  const cookie = getCookie("jwt-token")
  let isLoggedIn_ = true

  if (!cookie) {
    isLoggedIn_ = false;
  }

  await axios.post(BASE_URL + "/users/sync", {}, {
    headers: { 
      Authorization: "Bearer " + cookie 
    }
  })
  .then(res => {
    
  })
  .catch(err => {
    isLoggedIn_ = false;
  })

  return isLoggedIn_
}

export const isURL = (url: string) => {
  return url.startsWith("http://") || url.startsWith("https://") && url.split(".").length > 1 
}

export const isUrlInformationValid = (urlInformation: UrlInformationSchema, qrCodeType: QRCodeTypeSchema) => {
  if (qrCodeType === "url") {
    return isURL(urlInformation.url.url)
  }
  if (qrCodeType === "email") {
    return isEmail(urlInformation.email.email) && urlInformation.email.subject !== "" && urlInformation.email.body !== ""
  }
  if (qrCodeType === "call") {
    return urlInformation.call.phone !== "" && urlInformation.call.prefix !== ""
  }
  if (qrCodeType === "sms") {
    return urlInformation.sms.phone !== "" && urlInformation.sms.prefix !== "" && urlInformation.sms.message !== ""
  }
  if (qrCodeType === "wifi") {
    return urlInformation.wifi.ssid !== "" && urlInformation.wifi.password !== ""
  }
  if (qrCodeType === "geo") {
    return isURL(urlInformation.geo.maps_url)
  }
  if (qrCodeType === "contact") {
    return urlInformation.contact.name !== "" && urlInformation.contact.phone !== "" && urlInformation.contact.email !== "" && urlInformation.contact.address !== ""
  }
}

export const isEmail = (email: string) => {
  const pattern = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')
  return !!pattern.test(email)
}


export const getUser = async () => {
  const cookie: string | undefined = getCookie("jwt-token")
  let user = {}
  if (cookie !== undefined) {
    const userId = jwtDecode<any>(cookie).sub.user_id
    await axios.get(BASE_URL + "/users/" + userId, { headers: { Authorization: 'Bearer ' + getCookie("jwt-token") } })
    .then(res => {
      user = res.data.param
    })
    .catch(err => {
      
    })
  }
  return user
}

export const setCookie = (cookie: string, cValue: string, expDays: number) => {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookie + "=" + cValue + "; " + expires + "; path=/";
}

export const getCookie = (cookie: string) => {
  const name = cookie + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded .split('; ');
  let res;
  cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
  })
  return res;
}

export const eraseCookie = (cookie: string) => {   
  document.cookie = cookie+'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';  
}