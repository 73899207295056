import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/homepage';
import Dashboard from './pages/dashboard';
import QRCode from './pages/qrcode';
import Checkout from './pages/checkout';
import NotFound from './pages/not_found';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<><Homepage/></>}/>
        <Route path='/*' element={<><NotFound/></>}/>
        <Route path='/checkout/:plan_id' element={<><Checkout/></>}/>
        <Route path='/qrcode' element={<><QRCode/></>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
