import React from "react";
import { PlanSchema } from "../../types";
import { useNavigate } from "react-router-dom";
import Button from "../button";


interface PlanProps {
  plan: PlanSchema;
  loggedIn: boolean;
  ownPlan: boolean;
  purchasable: boolean;
  onGetStarted : () => void;
}


const Plan: React.FC<PlanProps> = ( props ) => {

  const navigate = useNavigate()

  return (
    <div className="shadow-md border mb-4 rounded-2xl">
      {props.plan.favorite ? (
        <div className="bg-[#7ed856] pt-1 pb-1 flex justify-around rounded-t-lg">
          <p className="text-sm text-[white] font-semibold">FAVORITE</p>
        </div>
      ): null}
      <div className="p-4">
        <h2 className="text-3xl font-bold text-center text-[#7ed856]">{props.plan.name}</h2>
        <div className="flex justify-around">
          <p className="text-center text-xs max-w-64 mt-4">Unlock the full potential of our platform with our Plans</p>
        </div>
        <div className="flex mt-4 justify-around">
          <div className="flex gap-2">
            <p className="font-extrabold flex text-[#2C2C2C] items-top">$<span className="text-5xl ml-1">{props.plan.cost_details.integer}</span>.{props.plan.cost_details.decimal}</p>
            <p className="text-xl line-through text-[#2C2C2C] decoration-[#7ed856]">{props.plan.old_cost}</p>
          </div>
        </div>
        <div className="flex items-center justify-around">
          <div className="flex mt-2 items-center gap-2">
            <p className="text-center text-xs max-w-72">Per month</p>
            {props.plan.discount != null ? (
              <div className="flex gap-2 font-semibold text-[black] text-xs rounded-full p-1 pl-2 pr-2 bg-[#7ed856]">
                <p>{props.plan.discount}% OFF</p>
              </div>
            ):(
              null
            )}
          </div>
        </div>
      </div>
      <div className="flex mt-2 p-2 bg-[gray] bg-opacity-10 justify-around">
        <p className="text-sm">{props.plan.max_scans} scans per day</p>
      </div>
      <div className="flex p-2 bg-opacity-10 justify-around">
        <p className="text-sm">{props.plan.max_scans * 30} scans per month</p>
      </div>
      <div className="flex p-2 bg-[gray] bg-opacity-10 justify-around">
        {props.plan.watermark ? ( 
          <p className="text-sm">Watermark</p>
        ):(
          <p className="text-sm">NO watermark</p>
        )}
      </div>
      {props.plan.customizable ? ( 
        <div className="flex p-2 bg-opacity-10 justify-around">
          <p className="text-sm">Customizable</p>
        </div>
      ):(
        <div className="flex p-2 bg-opacity-10 justify-around">
          <p className="text-sm">Not Customizable</p>
        </div>
      )}
      <div></div>
      <div className="flex justify-around p-6 items-center">
        {props.loggedIn ? (
          props.ownPlan ? (
            <Button
              onClick={() => {}}
              disabled
              className="rounded-full w-2/4 p-[12.4px]"
              text="YOUR PLAN"
            />
          ):(
            <Button
              onClick={() => navigate("/checkout/" + props.plan.plan_id)}
              className="rounded-full w-2/4 p-[12.4px]"
              text="BUY"
            />
          )
        ):(
          <Button
            onClick={props.onGetStarted}
            className="rounded-full w-2/4 font-semibold bg-[#7ed856] text-[white] text-sm p-[12.4px]"
            text="GET STARTED"
            disabled={!props.purchasable}
          />
        )}
        {/*<Button
          onClick={() => {}}
          disabled
          className="rounded-full w-2/4 font-semibold bg-[#7ed856] text-[white] text-sm p-[12.4px]"
          text="SOON..."
        />*/}
      </div>
    </div>
  )
}

export default Plan;