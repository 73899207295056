import { MdClose } from "react-icons/md";

interface ModalCloseProps {
  onClick: () => void;
}

const ModalClose: React.FC<ModalCloseProps> = ( props ) => {
  return (
    <button className="rounded-full p-[6px] hover:bg-[#f4f4f4] transition-all" onClick={props.onClick}>
      <MdClose color="gray" size={18}/>
    </button>
  )
}

export default ModalClose