import { useNavigate } from "react-router-dom";
import Button from "../../components/button";

const NotFound = () => {

  const navigate = useNavigate()

  return (
    <div className="flex justify-around items-center w-screen h-screen">
      <div>
        <h2 className="font-semibold text-[#7ed856] text-center text-5xl">404</h2>
        <p className="mt-2 text-center">This page was not found</p>
        <Button 
          onClick={() => navigate("/")} 
          text={"RETURN TO HOMEPAGE"} 
          className={"mt-6"}
        />
      </div>
    </div>
  );
}

export default NotFound;