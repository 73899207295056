import QRCode from "qrcode.react";
import { FaDownload, FaEnvelope, FaKey, FaLink, FaPhoneAlt, FaSms } from "react-icons/fa";
import { MdDashboardCustomize } from "react-icons/md";
import { QRCodeTypeSchema } from "../../types";


interface QRCodeProps {
  watermark: boolean;
  qrcode_type: QRCodeTypeSchema;
  customizable: boolean;
  logo_url: string;
  image_src?: string;
  name?: string;
  bgColor: string;
  fgColor: string;
  size: number;
  borderColor: string;
  customLogo: boolean;
  flow_url: string;
  showOptions?: boolean;
  onDownload?: () => void;
  onCustomizationClicked?: () => void;
  onDevKeyClicked?: () => void;
}

const ICONS: any = {
  call: <FaPhoneAlt/>,
  email: <FaEnvelope/>,
  sms: <FaSms/>,
  url: <FaLink/>
}

const CustomQRCode: React.FC<QRCodeProps> = ( props ) => {
  
  const generateStyle = () => {
    if (props.showOptions) {
      return "border-4 p-2 rounded-t-xl"
    }
    return "border-4 p-2 rounded-xl"
  }

  return(
    <div>
      <div style={{ backgroundColor: props.bgColor }} className="rounded-xl relative flex justify-around items-center " id="qrCodeEl">
        {props.image_src ? (
          <img
            draggable={false} 
            className={""}
            style={{ zIndex: 1 }} 
            src={"data:image/png;base64," + props.image_src} 
          />
        ):(
          <div className="relative flex justify-center items-center">
            {props.watermark ? (
              <img draggable={false} className="w-14 absolute" style={{ zIndex: 1 }} src={require("../../images/logo2.png")} />
            ) : (
              props.customLogo ? (
                <img draggable={false} className="rounded-full absolute w-14" style={{ zIndex: 1 }} src={props.logo_url} />
              ) : (
                null
              )
            )}
            <QRCode
              size={1920}
              bgColor={props.bgColor}
              fgColor={props.fgColor}
              style={{ height: "auto", borderColor: props.borderColor, maxWidth: "100%", width: props.size }}
              value={props.flow_url}
              viewBox={`0 0 256 256`}
              id="qrCode"
              className={generateStyle()}
            />
          </div>
        )}
      </div>
      {props.showOptions ? (
        <div className="h-14 flex justify-around rounded-b-xl items-center bg-[#7ed856]">
          <button onClick={props.onDownload} ><FaDownload size={24} color="white"/></button>
          <div className="bg-[white] rounded-full p-2 text-md text-[#7ed856]">{ICONS[props.qrcode_type]}</div>
          {props.customizable ? (
            <button onClick={props.onCustomizationClicked} ><MdDashboardCustomize size={24} color="white"/></button>
          ):(
            null
          )}
        </div>
      ):null}
    </div>
  )
}

export default  CustomQRCode;