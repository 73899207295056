import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, getCookie, getUser, isLoggedIn } from "../../utils";
import { PlanSchema, UserSchema } from "../../types";
import { PLAN } from "../../constants";
import axios from "axios";
import Loading from "../../components/loading";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";

const Checkout = () => {

  const { plan_id } = useParams();
  const [plan, setPlan] = useState<PlanSchema>(PLAN)
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>('')
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    handleAuth()
  }, [])

  const handleAuth = async () => {
    const loggedIn = await isLoggedIn()
    if (!loggedIn) {
      navigate("/")
      return
    }
    const user: UserSchema = (await getUser()) as UserSchema
    if (user.plan_id == parseInt(plan_id || "")) {
      navigate("/dashboard")
      return
    }
    getPlan()
  }

  const getPlan = async () => {
    await axios.get(BASE_URL + '/plans/' + plan_id)
    .then(res => {
      setPlan(res.data.param)
      if (!res.data.param.payment_required) {
        handleCheckout(res.data.param.plan_id, null)
      }
      setIsLoading(false)
    })
    .catch(err => {
      navigate("/")
    })
  }

  const handleCheckout = async (planId: number, paymentMethodId: any) => {
    setIsLoading(true)
    await axios.put(BASE_URL + '/users/update/plan', {
      plan_id: planId
    }, {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    })
    .then(res => {
      setIsLoading(false)
      navigate("/dashboard")
    })
    .catch(err => {
      console.log(err)
    })
  }

  const handleSubmit = async (e: any, planId: number) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("Card Element not found.");
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
    } else {
      handleCheckout(planId, paymentMethod.id);
    }
  }

  if (isLoading) {
    return (
      <Loading full_screen />
    )
  }
  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e, plan.plan_id)}>
        <CardElement/>
        <h1>Checkout, {plan.cost}</h1>
        <h1>{plan.payment_required ? "yes" : "no"}</h1>
        <button>ok</button>
      </form>
    </div>
  );
}

export default Checkout;