import React from "react";
import ModalClose from "../../modal_close";

interface WaitingListModalProps {
  visible: boolean;
  onClose: () => void;
}

const WaitingListModal: React.FC<WaitingListModalProps> = ( props ) => {

  if (!props.visible) {
    return null
  }

  return (
    <div>
      <div className="w-screen z-10 h-screen fixed flex justify-around items-center bg-[black] bg-opacity-30">
        <div className="bg-[white] w-80 top-0 p-6 rounded-xl">
          <div className="flex justify-between">
            <div></div>
            <ModalClose onClick={props.onClose} />
          </div>
          <img width={40.4} src={require("../../../images/logo2.png")} alt="" />
          <h1 className="font-semibold max-w-64 mt-2 text-xl">You successfully joined our waiting list.</h1>
          <p className="max-w-64 mt-4 text-sm">You'll get an email to access Bliddo before anyone else!</p>
          <p className="text-sm mt-4">Follow us on <span className="text-[#7ed856] underline font-semibold"><a href="https://x.com/@Bliddo_com" target="_blank">X</a></span> for any update.</p>
        </div>
      </div>
    </div>
  );
}

export default WaitingListModal
