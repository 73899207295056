import { useState } from "react";
import Input from "../input";

interface ColorPickerProps {
  color: any;
  label: string;
  className?: string;
  name: string;
  onChange: (color: any) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ( props ) => {

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.color)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  const createClassName = () => {
    return "flex justify-between items-center " + props.className || ""
  }

  return(
    <div className={createClassName()}>
      <p>{props.label}</p>
      <div className="gap-2 items-center flex">
        <label>
          <input name={props.name} value={props.color} onChange={(e) => props.onChange(e)} className="hidden" type="color"/>
          <div style={{ backgroundColor: props.color}} className="p-4 cursor-pointer hover:text-[#7ed856] text-[transparent] border rounded-lg">
          </div>
        </label>
        <div>
          <Input
            name={props.name} 
            type="text"
            className="w-24 h-8" 
            onChange={(e) => props.onChange(e)} 
            value={props.color}
          />
        </div>
      </div>
    </div>
  )
}

export default ColorPicker;