import React from "react";

interface InputProps{
  type: string;
  name: string;
  onChange: (e: any) => void;
  value: string;
  placeHolder?: string;
  label?: string;
  className?: string
}

const Input: React.FC<InputProps> = ( props ) => {

  const createStyle = () => {
    if (props.label) {
      return "border border-[#CDD7E1] bg-[#FBFCFE] focus:outline outline-[#7ed856] outline-1 mt-1 shadow-sm rounded-md p-2 h-full w-full"
    }
    return "border border-[#CDD7E1] bg-[#FBFCFE] focus:outline outline-1 outline-[#7ed856] shadow-sm rounded-md p-2 h-full w-full"
  }

  return (
    <div className={props.className}>
      {props.label ? (
        <p>{props.label}</p>
      ): null}
      <input 
        className={createStyle()}
        type={props.type}
        placeholder={props.placeHolder || ""}
        name={props.name}
        onChange={(e) => props.onChange(e)}
        value={props.value}
      />
    </div>
  )
}

export default Input