import { ChartDataSchema, PlanSchema, QRCodeSchema, TextDataSchema } from "../types";

export const TEXT_DATA: TextDataSchema = {
    scans: 0,
    old_scans: 0,
    label: "",
    daily_scans: 0,
    percentage: "",
    double_scans: 0,
    old_double_scans: 0
}


export const CHART_DATA: ChartDataSchema = {
    x: [],
    y: []
}

export const QRCODE: QRCodeSchema = {
    qrcode_id: 0,
    user_id: 0,
    name: "",
    qrcode_type: "url",
    url: "",
    image_src: "",
    bg_color: "#000000",
    fg_color: "#FFFFFF",
    border_color: "#7ed856",
    custom_logo: false,
    logo_url: "",
    flow_url: ""
}

export const PLAN: PlanSchema = {
    plan_id: 0,
    name: "",
    cost: 0,
    cost_details: {
        integer: 0,
        decimal: 0
    },
    discount: 0,
    old_cost: 0,
    customizable: false,
    favorite: false,
    watermark: false,
    max_scans: 0,
    payment_required: false
}

export const qrCodeSchema = {
    url: '',
    name: 'First QRCode',
    bg_color: '#ffffff',
    fg_color: '#000000',
    border_color: '#7ed856',
    logo_url: ''
}

export const verificationCodeSchema = {
    email: '',
    code: ""
}
  
export const dataSchema = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
}
  
export const urlInformationSchema = {
    url: {
        url: ''
    },
    email: {
        email: '',
        subject: '',
        body: ''
    },
    call: {
        phone: '',
        prefix: '+1'
    },
    sms: {
        phone: '',
        prefix: '+1',
        message: ''
    },
    wifi: {
        ssid: '',
        password: '',
        security: ''
    },
    geo: {
        maps_url: ''
    },
    contact: {
        name: '',
        phone: '',
        email: '',
        address: ''
    }
}

export const PREFIXES = {
    "AF": "+93",   // Afghanistan
    "AL": "+355",  // Albania
    "DZ": "+213",  // Algeria
    "AD": "+376",  // Andorra
    "AO": "+244",  // Angola
    "AG": "+1-268", // Antigua and Barbuda
    "AR": "+54",   // Argentina
    "AM": "+374",  // Armenia
    "AU": "+61",   // Australia
    "AT": "+43",   // Austria
    "AZ": "+994",  // Azerbaijan
    "BS": "+1-242", // Bahamas
    "BH": "+973",  // Bahrain
    "BD": "+880",  // Bangladesh
    "BB": "+1-246", // Barbados
    "BY": "+375",  // Belarus
    "BE": "+32",   // Belgium
    "BZ": "+501",  // Belize
    "BJ": "+229",  // Benin
    "BT": "+975",  // Bhutan
    "BO": "+591",  // Bolivia
    "BA": "+387",  // Bosnia and Herzegovina
    "BW": "+267",  // Botswana
    "BR": "+55",   // Brazil
    "BN": "+673",  // Brunei
    "BG": "+359",  // Bulgaria
    "BF": "+226",  // Burkina Faso
    "BI": "+257",  // Burundi
    "CV": "+238",  // Cabo Verde
    "KH": "+855",  // Cambodia
    "CM": "+237",  // Cameroon
    "CA": "+1",    // Canada
    "CF": "+236",  // Central African Republic
    "TD": "+235",  // Chad
    "CL": "+56",   // Chile
    "CN": "+86",   // China
    "CO": "+57",   // Colombia
    "KM": "+269",  // Comoros
    "CG": "+242",  // Congo
    "CR": "+506",  // Costa Rica
    "HR": "+385",  // Croatia
    "CU": "+53",   // Cuba
    "CY": "+357",  // Cyprus
    "CZ": "+420",  // Czech Republic
    "DK": "+45",   // Denmark
    "DJ": "+253",  // Djibouti
    "DM": "+1-767", // Dominica
    "DO": "+1-809", // Dominican Republic
    "EC": "+593",  // Ecuador
    "EG": "+20",   // Egypt
    "SV": "+503",  // El Salvador
    "GQ": "+240",  // Equatorial Guinea
    "ER": "+291",  // Eritrea
    "EE": "+372",  // Estonia
    "SZ": "+268",  // Eswatini
    "ET": "+251",  // Ethiopia
    "FJ": "+679",  // Fiji
    "FI": "+358",  // Finland
    "FR": "+33",   // France
    "GA": "+241",  // Gabon
    "GM": "+220",  // Gambia
    "GE": "+995",  // Georgia
    "DE": "+49",   // Germany
    "GH": "+233",  // Ghana
    "GR": "+30",   // Greece
    "GD": "+1-473", // Grenada
    "GT": "+502",  // Guatemala
    "GN": "+224",  // Guinea
    "GW": "+245",  // Guinea-Bissau
    "GY": "+592",  // Guyana
    "HT": "+509",  // Haiti
    "HN": "+504",  // Honduras
    "HU": "+36",   // Hungary
    "IS": "+354",  // Iceland
    "IN": "+91",   // India
    "ID": "+62",   // Indonesia
    "IR": "+98",   // Iran
    "IQ": "+964",  // Iraq
    "IE": "+353",  // Ireland
    "IL": "+972",  // Israel
    "IT": "+39",   // Italy
    "JM": "+1-876", // Jamaica
    "JP": "+81",   // Japan
    "JO": "+962",  // Jordan
    "KZ": "+7",    // Kazakhstan
    "KE": "+254",  // Kenya
    "KI": "+686",  // Kiribati
    "KP": "+850",  // Korea, North
    "KR": "+82",   // Korea, South
    "KW": "+965",  // Kuwait
    "KG": "+996",  // Kyrgyzstan
    "LA": "+856",  // Laos
    "LV": "+371",  // Latvia
    "LB": "+961",  // Lebanon
    "LS": "+266",  // Lesotho
    "LR": "+231",  // Liberia
    "LY": "+218",  // Libya
    "LI": "+423",  // Liechtenstein
    "LT": "+370",  // Lithuania
    "LU": "+352",  // Luxembourg
    "MG": "+261",  // Madagascar
    "MW": "+265",  // Malawi
    "MY": "+60",   // Malaysia
    "MV": "+960",  // Maldives
    "ML": "+223",  // Mali
    "MT": "+356",  // Malta
    "MH": "+692",  // Marshall Islands
    "MR": "+222",  // Mauritania
    "MU": "+230",  // Mauritius
    "MX": "+52",   // Mexico
    "FM": "+691",  // Micronesia
    "MD": "+373",  // Moldova
    "MC": "+377",  // Monaco
    "MN": "+976",  // Mongolia
    "ME": "+382",  // Montenegro
    "MA": "+212",  // Morocco
    "MZ": "+258",  // Mozambique
    "MM": "+95",   // Myanmar
    "NA": "+264",  // Namibia
    "NR": "+674",  // Nauru
    "NP": "+977",  // Nepal
    "NL": "+31",   // Netherlands
    "NZ": "+64",   // New Zealand
    "NI": "+505",  // Nicaragua
    "NE": "+227",  // Niger
    "NG": "+234",  // Nigeria
    "MK": "+389",  // North Macedonia
    "NO": "+47",   // Norway
    "OM": "+968",  // Oman
    "PK": "+92",   // Pakistan
    "PW": "+680",  // Palau
    "PA": "+507",  // Panama
    "PG": "+675",  // Papua New Guinea
    "PY": "+595",  // Paraguay
    "PE": "+51",   // Peru
    "PH": "+63",   // Philippines
    "PL": "+48",   // Poland
    "PT": "+351",  // Portugal
    "QA": "+974",  // Qatar
    "RO": "+40",   // Romania
    "RU": "+7",    // Russia
    "RW": "+250",  // Rwanda
    "KN": "+1-869", // Saint Kitts and Nevis
    "LC": "+1-758", // Saint Lucia
    "VC": "+1-784", // Saint Vincent and the Grenadines
    "WS": "+685",  // Samoa
    "SM": "+378",  // San Marino
    "ST": "+239",  // Sao Tome and Principe
    "SA": "+966",  // Saudi Arabia
    "SN": "+221",  // Senegal
    "RS": "+381",  // Serbia
    "SC": "+248",  // Seychelles
    "SL": "+232",  // Sierra Leone
    "SG": "+65",   // Singapore
    "SK": "+421",  // Slovakia
    "SI": "+386",  // Slovenia
    "SB": "+677",  // Solomon Islands
    "SO": "+252",  // Somalia
    "ZA": "+27",   // South Africa
    "SS": "+211",  // South Sudan
    "ES": "+34",   // Spain
    "LK": "+94",   // Sri Lanka
    "SD": "+249",  // Sudan
    "SR": "+597",  // Suriname
    "SE": "+46",   // Sweden
    "CH": "+41",   // Switzerland
    "SY": "+963",  // Syria
    "TW": "+886",  // Taiwan
    "TJ": "+992",  // Tajikistan
    "TZ": "+255",  // Tanzania
    "TH": "+66",   // Thailand
    "TL": "+670",  // Timor-Leste
    "TG": "+228",  // Togo
    "TO": "+676",  // Tonga
    "TT": "+1-868", // Trinidad and Tobago
    "TN": "+216",  // Tunisia
    "TR": "+90",   // Turkey
    "TM": "+993",  // Turkmenistan
    "TV": "+688",  // Tuvalu
    "UG": "+256",  // Uganda
    "UA": "+380",  // Ukraine
    "AE": "+971",  // United Arab Emirates
    "GB": "+44",   // United Kingdom
    "US": "+1",    // United States
    "UY": "+598",  // Uruguay
    "UZ": "+998",  // Uzbekistan
    "VU": "+678",  // Vanuatu
    "VA": "+379",  // Vatican City
    "VE": "+58",   // Venezuela
    "VN": "+84",   // Vietnam
    "YE": "+967",  // Yemen
    "ZM": "+260",  // Zambia
    "ZW": "+263"   // Zimbabwe
};
