import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useState } from "react";
import { MdDone, MdError } from "react-icons/md";
import { BASE_URL, isUrlInformationValid, setCookie } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { QRCodeTypeSchema, UrlInformationSchema } from "../../../types";
import { dataSchema, qrCodeSchema, urlInformationSchema, verificationCodeSchema } from "../../../constants";
import Input from "../../input";
import Button from "../../button";
import ModalClose from "../../modal_close";
import PinInput from "react-pin-input";
import PhonePrefixSelector from "../../phone_prefix_selector";

interface GetStartedModalProps {
  visible: boolean;
  onClose: () => void;
  qrCodeType: QRCodeTypeSchema,
  canSignup: boolean,
  canSignin: boolean,
  passwordRecoveryCode: string,
  planToBuy: number
}


const GetStartedModal: React.FC<GetStartedModalProps> = ( props ) => {

  const navigate = useNavigate()
  const [verificationCode, setVerificationCode] = useState(verificationCodeSchema)
  const [isVerifyingCode, setIsVerifyingCode] = useState(false)
  const [qrCode, setQrCode] = React.useState(qrCodeSchema)
  const [urlInformation, setUrlInformation] = React.useState<UrlInformationSchema>(urlInformationSchema)
  const [response, setResponse] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(dataSchema)
  const [error, setError] = useState('')
  const [passowrdForgot, setPasswordForgot] = useState(false)
  const [isSigningup, setIsSigningup] = useState(false)
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [readyToGo, setReadyToGo] = React.useState(false)


  const signupGoogle = async (res: any) => {
    setIsLoading(true)
    const data: any = jwtDecode(res.credential);
    console.log({
      email: data.email,
      first_name: data.given_name,
      last_name: data.family_name,
      qrcode: { 
        ...qrCode, 
        qrcode_type: props.qrCodeType, 
        url: buildUrl()
      }
    })
    await axios.post(BASE_URL + '/users/signup/google', {
      email: data.email,
      first_name: data.given_name,
      last_name: data.family_name,
      qrcode: { 
        ...qrCode, 
        qrcode_type: props.qrCodeType, 
        url: buildUrl()
      }
    })
    .then((res) => {
      setVerificationCode({ code: '', email: res.data.param.email })
      setIsVerifyingCode(true)
    })
    .catch((err) => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const handleCode = async () => {
    setIsLoading(true)
    await axios.post(BASE_URL + '/users/verify', {
      email: verificationCode.email, 
      code: parseInt(verificationCode.code)
    })
    .then((res) => {
      setIsVerifyingCode(false)
      setCookie('jwt-token', res.data.param.token, 14)
      navigate("/dashboard")
      navigate(0)
    })
    .catch((err) => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const handleUrlInformation = (e: any, value?: any) => {
    setUrlInformation({
      ...urlInformation,
      [props.qrCodeType]: {
        ...urlInformation[props.qrCodeType],
        [e.target.name]: value || e.target.value
      }
    })
  }

  const handleUrlPrefix = (value: any) => {
    setUrlInformation({
      ...urlInformation,
      [props.qrCodeType]: {
        ...urlInformation[props.qrCodeType],
        prefix: value
      }
    })
  }

  const signinGoogle = async (res: any) => {
    setIsLoading(true)
    const data: any = jwtDecode(res.credential);
    await axios.post(BASE_URL + '/users/signin/google', {
      email: data.email
    })
    .then((res) => {
      setVerificationCode({ code: '', email: res.data.param.email })
      setIsVerifyingCode(true)
    })
    .catch((err) => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const handleData = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleClose = () => {
    setData(dataSchema)
    setVerificationCode(verificationCodeSchema)
    setQrCode(qrCodeSchema)
    setIsVerifyingCode(false)
    setIsSigningup(false)
    setIsSigningIn(false)
    setReadyToGo(false)
    setError('')
    setUrlInformation(urlInformationSchema)
    setResponse('')
    setPasswordForgot(false)

    if (props.passwordRecoveryCode !== "") {
      navigate("/")
    }

    props.onClose()
  }

  const buildUrl = () => {
    if (props.qrCodeType === "url") {
      return urlInformation.url.url
    }
    if (props.qrCodeType === "email") {
      return `mailto:${urlInformation.email.email}?subject=${urlInformation.email.subject}&body=${urlInformation.email.body}`
    }
    if (props.qrCodeType === "call") {
      return `tel:${urlInformation.call.prefix} ${urlInformation.call.phone}`
    }
    if (props.qrCodeType === "sms") {
      return `sms:${urlInformation.sms.prefix} ${urlInformation.sms.phone};?&body=${urlInformation.sms.message}`
    }
    if (props.qrCodeType === "wifi") {
      return `WIFI:T:WPA;S:${urlInformation.wifi.ssid};P:${urlInformation.wifi.password};;`
    }
    if (props.qrCodeType === "geo") {
      const url = urlInformation.geo.maps_url.split('@')[1].split("z")[0]
      return `geo:${url[0]},${url[1]}?z=${url[2]}`
    }
    if (props.qrCodeType === "contact") {
      return `MECARD:N:${urlInformation.contact.name};TEL:${urlInformation.contact.phone};EMAIL:${urlInformation.contact.email};ADR:${urlInformation.contact.address};;`
    }
  }

  const signin = async () => {
    setIsLoading(true)
    await axios.post(BASE_URL + '/users/signin', data)
    .then((res) => {
      setVerificationCode({ code: '', email: res.data.param.email })
      setIsVerifyingCode(true)
    })
    .catch((err) => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const signup = async () => {
    setIsLoading(true)
    await axios.post(BASE_URL + '/users/signup', { 
      ...data, 
      qrcode: { 
        ...qrCode, 
        qrcode_type: props.qrCodeType, 
        url: buildUrl()
      }
  })
    .then((res) => {
      setVerificationCode({ code: '', email: res.data.param.email })
      setIsVerifyingCode(true)
    })
    .catch((err) => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const recoverPassword = async () => {
    setIsLoading(true)
    await axios.post(BASE_URL + '/users/recover', data)
    .then((res) => {
      setResponse(res.data.param)
      setError("")
    })
    .catch((err) => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const canSignin = () => {
    return data.email != '' && data.password != ''
  }

  const canRecoverPassword = () => {
    return data.password.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
  }

  const canSignup = () => {
    return data.first_name != '' && 
    data.last_name != '' && 
    data.email.match('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$') && 
    data.password.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
  }

  const changePassword = async () => {
    setIsLoading(true)
    await axios.post(BASE_URL + '/users/change/password', { ...data, code: props.passwordRecoveryCode })
    .then((res) => {
      setResponse(res.data.param)
      setError("")
    })
    .catch((err) => {
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  if (!props.visible) {
    return null
  }

  return (
    <div>
      <div className="w-screen z-10 h-screen fixed flex justify-around items-center bg-[black] bg-opacity-30">
        <div className="bg-[white] w-80 top-0 p-6 rounded-xl">
          <div className="flex justify-between">
            <div></div>
            <ModalClose onClick={handleClose}/>
          </div>
          <img width={40.4} src={require("../../../images/logo2.png")} alt="" />
          <h1 className="font-semibold max-w-64 mt-2 text-xl">You're almost there, one more step!</h1>
          {isVerifyingCode ? (
            <div>
              <p className="text-sm mt-2">Check your <a href="https://mail.google.com/mail/u/0/#advanced-search/from=bliddo.com%40gmail.com" target="_blank" className="underline">email</a> and write your verification code here:</p>
              <div className="flex justify-around">
                <PinInput
                  length={4} 
                  initialValue=""
                  onChange={(value: string, index: number) => setVerificationCode({ ...verificationCode, code: value })} 
                  type="numeric" 
                  inputMode="number"
                  style={{ marginTop: '14px', marginLeft: '-14px' }}  
                  inputStyle={{borderColor: '#CDD7E1', marginLeft: '14px', borderRadius: '10px'}}
                  inputFocusStyle={{borderColor: '#7ed856'}}
                  autoSelect={true}
                  regexCriteria={/^[0-9]*$/}
                />
              </div>
              <p className="text-sm mt-2 text-center text-[red]">{error}</p>
              <Button
                onClick={handleCode}
                text="VERIFY"
                disabled={verificationCode.code.length != 4}
                isLoading={isLoading}
                className="mt-[34px]"
              />
            </div>
          ):(
            isSigningup ? (
              <div className="mt-4">
                <Input 
                  onChange={(e) => handleData(e)} 
                  label="First Name"
                  type="text"
                  placeHolder="Enter your name"
                  name="first_name" 
                  value={data.first_name}
                />
                <Input 
                  onChange={(e) => handleData(e)} 
                  type="text"
                  className="mt-2"
                  label="Last Name"
                  placeHolder="Enter your last name"
                  name="last_name" 
                  value={data.last_name}
                />
                <Input 
                  onChange={(e) => handleData(e)} 
                  type="text"
                  className="mt-2"
                  placeHolder="email@example.com"
                  label="E-mail"
                  name="email"
                  value={data.email}
                />
                <div className="flex justify-between items-center">
                  <Input
                    onChange={(e) => handleData(e)} 
                    type="password"
                    className="mt-2 w-60"
                    placeHolder="********"
                    label="Password"
                    name="password"
                    value={data.password}
                  />
                  {data.password.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$') ? (
                    <MdDone className="mt-8" color="#7ed856" size={24}/>
                  ):(
                    <MdError color="#ff5d5c" className="mt-10" size={24}/>
                  )}
                </div>
                <p className="text-sm text-[red]">{error}</p>
                <Button
                  onClick={signup}
                  text="SIGNUP"
                  disabled={!canSignup()}
                  isLoading={isLoading}
                  className="mt-[34px]"
                />
              </div>
            ):(
              passowrdForgot ? (
                <div>
                  <Input 
                    onChange={(e) => handleData(e)} 
                    type="text"
                    label="Your E-mail"
                    placeHolder="email@example.com"
                    className="mt-4"
                    name="email" 
                    value={data.email}
                  />
                  <p className="text-sm text-[green] max-w-64">{response}</p>
                  <p className="text-sm text-[red]">{error}</p>
                  <Button
                    disabled={data.email == ""}
                    isLoading={isLoading}
                    onClick={recoverPassword}
                    text="NEXT"
                    className="mt-[34px]"
                  />
                </div>
              ):(
                isSigningIn ? (
                  <div>
                    <Input 
                      onChange={(e) => handleData(e)} 
                      type="text"
                      className="mt-6"
                      label="E-mail"
                      placeHolder="email@example.com"
                      name="email" 
                      value={data.email}
                    />
                    <Input 
                      onChange={(e) => handleData(e)} 
                      type="password"
                      placeHolder="********"
                      label="Password"
                      className="mt-2"
                      name="password" 
                      value={data.password}
                    />
                    <button onClick={() => setPasswordForgot(true)}>
                      <p className="underline text-sm">Forgot password?</p>
                    </button>
                    <p className="text-sm text-[red]">{error}</p>
                    <Button
                      disabled={!canSignin()}
                      isLoading={isLoading}
                      onClick={signin}
                      text="SIGNIN"
                      className="mt-[34px]"
                    />
                  </div>
                ):(
                  props.canSignup ? (
                    readyToGo ? (
                      <div className="mt-6">
                        {isLoading ? (
                          <div className="w-[272px] flex justify-around items-center bg-[white] bg-opacity-70 absolute z-20 h-[174px]">
                            <SpinnerCircular color="#7ed856" secondaryColor="#f4f4f4" thickness={184} size={45} />
                          </div>
                        ): null}
                        <div className="w-full">
                          <Button
                            onClick={() => setIsSigningup(true)}
                            text="REGISTER"
                            className="mt-2 pt-[10px] pb-[10px]"
                          />
                        </div>
                        <div className="w-full mt-[14px]">
                          <GoogleLogin
                            onSuccess={(res: any) => signupGoogle(res)}
                            onError={() => {

                            }}
                            width={273}
                            text="signup_with"
                          />
                        </div>
                        <p className="text-sm mt-[14px] text-center text-[red]">{error}</p>
                        <p className="text-[10px] mt-8 max-w-64 text-center">
                          By proceeding, you agree to our <a className="underline" href={BASE_URL + "/users/tou"}>Terms of Use</a> and confirm you have read our
                          <a className="underline" href={BASE_URL + "/users/pcs"}>Privacy and Cookie Statement</a>
                        </p>
                      </div>
                    ):(
                      <div>
                        <Input 
                          onChange={(e) => setQrCode({ ...qrCode, name: e.target.value })} 
                          type="text"
                          placeHolder="My QR Code"
                          name="name"
                          className="mt-6"
                          label="Name"
                          value={qrCode.name}
                        />
                        {props.qrCodeType === "url" ? (
                          <Input
                            type="text"
                            label="Url"
                            placeHolder="Enter the url"
                            className="mt-2"
                            name="url"
                            onChange={(e) => handleUrlInformation(e)}
                            value={urlInformation.url.url}
                          />
                        ):(
                          props.qrCodeType === "email" ? (
                            <div>
                              <Input
                                type="text"
                                label="E-mail"
                                placeHolder="Enter the e-mail"
                                className="mt-6"
                                name="email"
                                onChange={(e) => handleUrlInformation(e)}
                                value={urlInformation.email.email}
                              />
                              <Input 
                                type="text"
                                label="Subject"
                                placeHolder="Enter the subject"
                                className="mt-2"
                                name="subject"
                                onChange={(e) => handleUrlInformation(e)}
                                value={urlInformation.email.subject}
                              />
                              <Input 
                                type="text"
                                className="mt-2"
                                label="Body"
                                placeHolder="Enter the body"
                                name="body"
                                onChange={(e) => handleUrlInformation(e)}
                                value={urlInformation.email.body}
                              />
                            </div>
                          ):(
                            props.qrCodeType === "call" ? (
                              <div className="mt-4">
                                <p>Phone</p>
                                <div className="flex gap-2">
                                  <PhonePrefixSelector
                                    onChange={(value) => handleUrlPrefix(value)}
                                  />
                                  <Input 
                                    type="text"
                                    placeHolder="123 456 7890"
                                    name="phone"
                                    onChange={(e) => handleUrlInformation(e)}
                                    value={urlInformation.call.phone}
                                  />
                                </div>
                              </div>
                            ):(
                              props.qrCodeType === "sms" ? (
                                <div>
                                  <div className="mt-4">
                                    <p>Phone</p>
                                    <div className="flex gap-2">
                                      <PhonePrefixSelector
                                        onChange={(value) => handleUrlPrefix(value)}
                                      />
                                      <Input 
                                        type="text"
                                        placeHolder="123 456 7890"
                                        name="phone"
                                        onChange={(e) => handleUrlInformation(e)}
                                        value={urlInformation.sms.phone}
                                      />
                                    </div>
                                  </div>
                                  <Input 
                                    type="text"
                                    placeHolder="Enter the message"
                                    label="Message"
                                    className="mt-2"
                                    name="message"
                                    onChange={(e) => handleUrlInformation(e)}
                                    value={urlInformation.sms.message}
                                    />
                                </div>
                              ):(
                                props.qrCodeType === "wifi" ? (
                                  <div>
                                    <Input 
                                      type="text"
                                      placeHolder="Enter the SSID"
                                      label="SSID"
                                      className="mt-6"
                                      name="ssid"
                                      onChange={(e) => handleUrlInformation(e)}
                                      value={urlInformation.wifi.ssid}
                                    />
                                    <Input 
                                      type="text"
                                      placeHolder="***************"
                                      label="Password"
                                      className="mt-2"
                                      name="password"
                                      onChange={(e) => handleUrlInformation(e)}
                                      value={urlInformation.wifi.password}
                                    />
                                  </div>
                                ):(
                                  props.qrCodeType === "geo" ? (
                                    <Input 
                                      type="text"
                                      placeHolder="https://maps.google.com"
                                      label="Google Maps URL"
                                      className="mt-6"
                                      onChange={(e) => handleUrlInformation(e)}
                                      name="maps_url"
                                      value={urlInformation.geo.maps_url}
                                    />
                                  ):(
                                    props.qrCodeType === "contact" ? (
                                      <div>
                                        <Input 
                                          type="text"
                                          placeHolder="Enter the name"
                                          label="Name"
                                          className="mt-6"
                                          name="name"
                                          onChange={(e) => handleUrlInformation(e)}
                                          value={urlInformation.contact.name}
                                        />
                                        <Input 
                                          type="text"
                                          placeHolder="Enter the e-mail"
                                          label="E-mail"
                                          className="mt-2"
                                          name="email"
                                          onChange={(e) => handleUrlInformation(e)}
                                          value={urlInformation.contact.email}
                                        />
                                        <Input 
                                          type="text"
                                          placeHolder="Enter the phone"
                                          label="Phone"
                                          className="mt-2"
                                          name="phone"
                                          onChange={(e) => handleUrlInformation(e)}
                                          value={urlInformation.contact.phone}
                                        />
                                        <Input 
                                          type="text"
                                          placeHolder="Enter the address"
                                          label="Address"
                                          className="mt-2"
                                          name="address"
                                          onChange={(e) => handleUrlInformation(e)}
                                          value={urlInformation.contact.address}
                                        />
                                      </div>
                                    ):(
                                      null
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )}
                        <Button
                          onClick={() => setReadyToGo(true)}
                          disabled={qrCode.name.length == 0 || qrCode.name.length > 18 || !isUrlInformationValid(urlInformation, props.qrCodeType)}
                          text="NEXT"
                          className="mt-[34px]"
                        />
                      </div>
                    )
                  ):(
                    props.canSignin ? (
                      <div className="mt-6">
                        {isLoading ? (
                          <div className="w-[272px] flex justify-around items-center bg-[white] bg-opacity-70 absolute z-20 h-[174px]">
                            <SpinnerCircular color="#7ed856" secondaryColor="#f4f4f4" thickness={184} size={45} />
                          </div>
                        ): null}
                        <div className="w-full">
                          <Button
                            onClick={() => setIsSigningIn(true)}
                            text="SIGN IN"
                            className="mt-2 pt-[10px] pb-[10px]"
                          />
                        </div>
                        <div className="w-full mt-[14px]">
                          <GoogleLogin
                            onSuccess={(res: any) => signinGoogle(res)}
                            onError={() => {
                              
                            }}
                            width={273}
                          />
                        </div>
                        <p className="text-sm mt-[14px] text-center text-[red]">{error}</p>
                        <p className="text-[10px] mt-8 max-w-64 text-center">
                          By proceeding, you agree to our <a className="underline" href={BASE_URL + "/users/tou"}>Terms of Use</a> and confirm you have read our
                          <a className="underline" href={BASE_URL + "/users/pcs"}>Privacy and Cookie Statement</a>
                        </p>
                      </div>
                    ):(
                      <div className="mt-6">
                        <p>Create a password</p>
                        <div className="flex justify-between items-center">
                          <Input
                            onChange={(e) => handleData(e)} 
                            type="password"
                            className="mt-2 w-60"
                            placeHolder="********"
                            name="password"
                            value={data.password}
                          />
                          {data.password.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$') ? (
                            <MdDone className="mt-2" color="#7ed856" size={24}/>
                          ):(
                            <MdError color="#ff5d5c" className="mt-2" size={24}/>
                          )}
                        </div>
                        <p className="text-sm text-[green] max-w-64">{response}</p>
                        <p className="text-sm text-[red]">{error}</p>
                        <Button
                          isLoading={isLoading}
                          text="RECOVER"
                          className="mt-[24px]"
                          onClick={changePassword}
                          disabled={!canRecoverPassword()}
                        />
                      </div>
                    )
                  )
                )
              )
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default GetStartedModal
