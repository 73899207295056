import { MdEmail } from "react-icons/md";
import { BASE_URL } from "../../utils";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="w-full pt-40 flex p-8 justify-around">
      <div>
        <div className="flex items-center gap-14">
          <div className="flex items-center gap-1">
            <img width={34} src={require("../../images/logo2.png")} alt="" />
            <p>© 2024 Bliddo.</p>
          </div>
          <div className="flex gap-4">
            <div>
              <a target="_blank" href="https://x.com/@bliddo_com"><FaXTwitter size={18}/></a>
            </div>
            <div>
              <a target="_blank" href="mailto:bliddo.com@gmail.com"><MdEmail size={18}/></a>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex justify-around"><a className="underline text-xs" href={BASE_URL + "/users/tou"}>Terms of Use</a></div>
          <div className="flex justify-around mt-1"><a className="underline text-xs" href={BASE_URL + "/users/pcs"}>Privacy and Cookie</a></div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;