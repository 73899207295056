import { useEffect } from "react"
import { BASE_URL } from "../../utils"
import { useSearchParams } from "react-router-dom"
import axios from "axios"

const QRCode: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  
  const getIp = async () => {
    await axios.get('https://api.ipdata.co/?api-key=' + process.env.REACT_APP_IPDATA_API_KEY)
    .then(res => {
      sendData(res.data.ip, res.data.country_code, res.data.country_name, res.data.city || "No city")
    })
    .catch(err => {
      window.location.href = searchParams.get("url") || ""
    })
  }

  const sendData = async (ip: string, countryCode: string, countryName: string, city: string) => {
    const device = navigator.userAgent.split('(')[1].split(' ')[0].replace(";", "")
    await axios.post(BASE_URL + '/accesses/', {
      ip_address: ip,
      city: city,
      device: device,
      country_code: countryCode,
      country_name: countryName,
      qrcode_id: searchParams.get("qrcode_id")
    })
    .then(res => {
     window.location.href = res.data.param.url
    })
    .catch(err => {
      const url = searchParams.get("url")
      window.location.replace(url || "")
    })
  }
  
  useEffect(() => {
    getIp()
  }, [])

  return (
    <div>
      
    </div>
  )
}

export default QRCode